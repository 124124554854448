import React, {useEffect, useState} from 'react';
import {
    Form,
    Modal,
    Input,
    Button,
    Row,
    Col,
    Upload,
    Tabs,
    Select
} from 'antd';
import { REQUIRED_MSG } from '../constants/common';
import { imageService } from '../services/imageService';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import service from './../core/setAxiosOptions';
import DragAndDropSelect from './DragAndDropSelect';
import { imageInfoToAntdModel, imageURLToAntdModel } from '../helpers/imageToAntdModel';
import { uploadCustomRequest, updateImagesList } from '../helpers/filesLoadHandling';
import { checkFileSize } from '../helpers/checkFileSize';
import { truncateString } from './../helpers/truncateString';

const { TextArea } = Input;
const { TabPane } = Tabs;

const locationsUrl = 'route_location';
const selectURL = 'route_category';

const ModalEditRoute: React.FC<any> = ({
    defaultItem,
    visible,
    handleCancel,
    handleOk,
    dataURL
}) => {
    const [form] = Form.useForm();

    const [bgImage, setBgImage] = useState(defaultItem?.bgImage ? [imageURLToAntdModel(defaultItem.bgImage)] : [] as any[]);


    const [categories, setCategories] = useState<any[]>([]);

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = () => {
        service.get(selectURL).then(res => {
            setCategories(res.data);
        });
    };

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            const res: any = {
                ...(defaultItem ? { _id: defaultItem._id } : {}),
                titleEN: values.titleEN,
                titleUA: values.titleUA,
                shortTextUA: values.shortTextUA,
                shortTextEN: values.shortTextEN,
                category: values.category,
                bgImage: bgImage[0]?.url || null,
                ...(values?.locations ? {locations: values?.locations?.map((location: any) => location._id) || []} : {})
            };

            const result = await handleOk(res);
            if(result.status === 200) {
                form.resetFields();
            }
        });
    };

    const onCancel = () => {
        form.resetFields();
        handleCancel();
    };

    const handleUploadChange = (listName: string, setList: any) =>
        (info: UploadChangeParam<UploadFile<any>>) => {
            const list = eval(listName);
            if (info.file.status === 'removed') {
                const toUpdate = list.filter(image => image.uid !== info.file.uid);
                setList(toUpdate);
                imageService.deleteImage(info.file.uid).then(() => updateImagesList(defaultItem?._id, listName, toUpdate, dataURL, false));
            } else if (info.file.status) {
                const images = [...list];
                const imageToAdd = imageInfoToAntdModel(info);
                setList(
                    images.some(image => image.uid === info.file.uid) ? 
                        images.map(image => image.uid === info.file.uid ? imageToAdd : image) : [...images, imageToAdd]
                );
            }
        };

    return(
        <Modal
            centered
            maskClosable={false}
            title={defaultItem ? 'Редагувати' : 'Створити'}
            visible={visible}
            onCancel={onCancel}
            bodyStyle={{
                maxHeight: '75vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            footer={
                <Row justify={'end'}>
                    <Button onClick={onCancel}>Вiдмiна</Button>
                    <Button type={'primary'} onClick={onFinish}>
                        {defaultItem ? 'Зберегти' : 'Створити'}
                    </Button>
                </Row>
            }
            width={'80%'}
        >
            <Form
                form={form}
                name="basic"
                initialValues={{
                    ...defaultItem,
                    category: defaultItem?.category?._id
                }}
                layout={'vertical'}
            >
                <Tabs defaultActiveKey="params">
                <TabPane tab="Параметри" key="params">
                <Row style={{ justifyContent: 'space-around' }}>
                <Col span={11}>
                            <Form.Item
                                label="Категорiя"
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: REQUIRED_MSG,
                                    },
                                ]}
                            >
                                <Select>
                                    {categories &&
                                    categories.map(c =>
                                        <Select.Option key={c._id} value={c._id}>{c.titleUA}/{c.titleEN}</Select.Option>
                                    )
                                    }
                                </Select>
                            </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Зображення маршруту"
                            name="bgImage"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={checkFileSize}
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={bgImage}
                                onChange={handleUploadChange('bgImage', setBgImage)}
                            >
                                <Button disabled={!!bgImage?.length}>Завантажити</Button>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Назва маршруту (UA)"
                            name="titleUA"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Назва маршруту (EN)"
                            name="titleEN"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Короткий опис (UA)"
                            name="shortTextUA"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                }
                            ]}>
                            <TextArea rows={3}/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Короткий опис (EN)"
                            name="shortTextEN"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                }
                            ]}>
                            <TextArea rows={3}/>
                        </Form.Item>
                    </Col>
                    
                </Row>
                </TabPane>
                <TabPane tab="Локації" key="locations">
                    <Form.Item
                        name="locations"
                    >
                        <DragAndDropSelect
                            dictionaryURL={locationsUrl}
                            selected={form.getFieldValue('locations')}
                            setSelected={(items) => form.setFieldsValue({
                                locations: items
                            })}
                            labelKey={'_id'}
                            displayedValue={(item) => (
                                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                    <div>{truncateString(item.titleUA, 50)}<br/></div>
                                    <div style={{flex:1, display: 'flex', textAlign: 'center'}}>
                                       <span style={{alignSelf: 'flex-end', marginBottom: 0, flex:1}}> {item.time}</span>      
                                    </div>
                                </div>)}
                            displayedDropdownValue={'titleUA'}/>
                    </Form.Item>
                </TabPane>
                </Tabs>
            </Form>
        </Modal>
    );

};

export default ModalEditRoute;