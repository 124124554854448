import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';

const museumURL = '/museum_history';
const categoryDictionary = 'museum_category';
const deelLink = 'media/museumHistory';

const MuseumPage = () => {

    return(
        <CommonArticlePage
            deepLink={deelLink}
            breadcrumbItems={['Історії', 'Медіатека']}
            dataURL={museumURL}
            selectURL={categoryDictionary}
            imageParams={{required: true}}
            columns={[
                columns.title,
                columns.shortText,
                columns.category(categoryDictionary),
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}
        />
    );
};

export default MuseumPage;