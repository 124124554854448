import React from 'react';
import AboutPage from '../components/AboutPage';

const dataCode = 'app';

const AboutAppPage = () => {
    return (
        <AboutPage dataCode={dataCode}/>
    )
};

export default AboutAppPage;