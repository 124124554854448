import React, {useEffect, useState} from 'react';
import {
    Form,
    Modal,
    Input,
    Button,
    Row,
    Col,
    Upload,
    Tabs
} from 'antd';
import iconUA from '../assets/images/icon-ua.svg';
import iconEN from '../assets/images/icon-en.svg';

const baseURL = process.env.REACT_APP_BASE_URL || 'http://huspi.com:5600/api/';

const ModalEditRoute: React.FC<any> = ({
    item,
    visible,
    handleCancel
}) => {
    const [isUA, setIsUA] = useState(true);

    const preview = {
        src: (item || {})[isUA ? 'imageUA' : 'imageEN'],
        title: (item || {})[isUA ? 'titleUA' : 'titleEN'],
        text: (item || {})[isUA ? 'textUA' : 'textEN']
    };

    return(
            <Modal
                className="mobile-preview-body"
                centered
                closable={false}
                visible={visible}
                onCancel={handleCancel}
                bodyStyle={{
                    height: '600px',
                    
                }}
                footer={null}
            >
                <div className="mobile-container">
                    <div className="mobile-wrapper">
                        <div className="preview-article">
                        <span className="object-close" onClick={() => setIsUA(!isUA)}>
                            <img src={isUA ? iconUA : iconEN}/>
                        </span>
                            {preview.src && <figure className="object-pic"><img src={`${preview.src}`} className="object-img"/></figure>}
                            <article className="object-content">
                                <p className="h1">{preview.title}</p>
                                <div className="article-text" dangerouslySetInnerHTML={{__html: preview.text}}/>
                            </article>
                        </div>
                    </div>
                </div>
                <div className="mobile-button-container">
                    <div className="mobile-button"/>
                </div>
            </Modal>
    );

};

export default ModalEditRoute;