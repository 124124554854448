import service from './../core/setAxiosOptions';

export const audioService = {
    getAudio: (id: string) => service.get(`audio/${id}`),
    uploadAudio: (file: any) => service.post<{ _id: string}>('audio', file, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then(response => response.data),
    deleteAudio: (id: string) => service.delete(`audio/${id}`),
}