import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';
import { ImageCollectionType } from '../typings/IImageParams';

const fakeNewsURL = '/fake_news';
const deelLink = 'fakes';
const fakeNewsIconsURL = 'fake_icon';

const FakesPage = () => {
    return(
        <CommonArticlePage
            deepLink={deelLink} 
            breadcrumbItems={['Інформація', 'Фейки']}
            dataURL={fakeNewsURL}
            selectURL={fakeNewsIconsURL}
            isFake={true}
            imageParams={{type: ImageCollectionType.none}}
            columns={[
                columns.title,
                columns.shortText,
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}
        />
    );
};

export default FakesPage;
