
export enum ImageCollectionType {
    set,
    single,
    none
}

export interface IImageParams {
    type?: ImageCollectionType;
    required?: boolean;
}