import { parseFilters, filtersObject } from './../components/Search';

interface IPagination {
    page: number;
    pageSize: number;
}

const buildQuery = (pagination: IPagination, filters?: filtersObject) => {
    return `?page=${pagination.page}&limit=${pagination.pageSize}${parseFilters(filters)}`;
};

export default buildQuery;
