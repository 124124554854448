import React, { useMemo, useState } from 'react';
import SunEditor from 'suneditor-react';
import { checkFileSize } from '../helpers/checkFileSize';
import 'suneditor/dist/css/suneditor.min.css';

interface IRichTextEditorProps {
    value: any;
    setValue: any;
    deleteImage: (id: string) => Promise<any>
    saveImage: (data: FormData) => Promise<any>
}

const buttonlist = [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['removeFormat'],
    ['fontColor', 'hiliteColor'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list'],
    ['link', 'image', 'video'],
    ['fullScreen'],
    ['codeView']
];

const baseURL = process.env.REACT_APP_BASE_URL || 'http://huspi.com:5600/api/';

const RichTextEditor = ({ setValue, value, saveImage, deleteImage }: IRichTextEditorProps) => {
    const handleImageUploadBefore = (files, info, uploadHandler) => {
        if(checkFileSize(files[0])) {
            const fData = new FormData();
            fData.append('file', files[0]);
            saveImage(fData)
                .then(data => {
                    const id = data._id;
                    const url = `${baseURL}image/` + id;
                    const response = {
                        result: [
                            {
                                url,
                                name: id,
                            },
                        ],
                    };
                    uploadHandler(response);
                });
        }
        return false;
    };
    
    return (
        <SunEditor
            lang={'ru'}
            setContents={value}
            onChange={content => setValue(content)}
            onImageUploadBefore={handleImageUploadBefore}
            setOptions={{
                height: 200,
                imageUrlInput: false,
                imageResizing: false,
                buttonList: buttonlist, // Or Array of button list, eg. [['font', 'align'], ['image']]
                // Other option
            }}
        />
    );
};

export default RichTextEditor;
