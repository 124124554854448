import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';
import { ImageCollectionType } from '../typings/IImageParams';

const tickerURL = '/ticket';
const deelLink = 'detail/buyTicket'

const TicketPage = () => {
    return(
        <CommonArticlePage
            deepLink={deelLink}
            breadcrumbItems={['Наважитись', 'Купити квиток']}
            dataURL={tickerURL}
            imageParams={{type: ImageCollectionType.none}}
            columns={[
                columns.title,
                columns.shortText,
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}/>
    );
};

export default TicketPage;