export const REQUIRED_MSG = 'Це поле обов\'язкове';
export const LOGIN_NOT_ALLOWED_MSG = 'Пробіли не дозволено';
export const PUBLICIZE_CONFIRM = 'Ви дійсно бажаєте опублікувати запис?';
export const CANCEL_PUBLICIZE_CONFIRM = 'Ви дійсно бажаєте відмінити публікацію?';

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm:ss';

export const PUBLISH_DATE_TIME_FORMAT = 'MM-DD-YYYY';

export const MAX_IMG_COUNT = 7;