import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';

const declassifiedDocURL = '/declassified_doc';
const categoryDictionary = 'declassified_category';
const deelLink = 'detail/declassifiedHistory';

const DeclassifiedDocPage = () => {

    return(
        <CommonArticlePage
            deepLink={deelLink}
            breadcrumbItems={['Історії', 'Розсекречені документи']}
            dataURL={declassifiedDocURL}
            selectURL={categoryDictionary}
            columns={[
                columns.title,
                columns.shortText,
                columns.category(categoryDictionary),
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}
        />
    );
};

export default DeclassifiedDocPage;