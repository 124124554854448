import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';
import ModalEditRoute from '../components/ModalEditRoute';

const dataURL = '/route';

const RouteConstructorPage = () => {
    return(
        <CommonArticlePage
            breadcrumbItems={['Маршрути', 'Конструктор']}
            dataURL={dataURL}
            customModal={ModalEditRoute}
            columns={[
                columns.title,
                columns.shortText,
                columns.routeLocation,
                columns.getOperations(false),
            ]}
        />
    );
};

export default RouteConstructorPage;
