import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';

const dataUrl = '/activity';
const categoryDictionary = 'activities_category';
const deelLink = 'media/activitiesArt';

const ActivitiesPage = () => {

    return(
        <CommonArticlePage
            deepLink={deelLink}
            breadcrumbItems={['Мистецтво', 'Заходи']}
            dataURL={dataUrl}
            selectURL={categoryDictionary}
            columns={[
                columns.title,
                columns.shortText,
                columns.category(categoryDictionary),
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}
        />
    );
};

export default ActivitiesPage;