import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';

const articleURL = '/people_history';
const categoryDictionary = 'people_category';
const deelLink = 'detail/peopleHistory';

const PeopleHistoryPage = () => {
    return(
        <CommonArticlePage
            deepLink={deelLink}
            breadcrumbItems={['Історії', 'Люди']}
            dataURL={articleURL}
            selectURL={categoryDictionary}
            columns={[
                columns.title,
                columns.shortText,
                columns.category(categoryDictionary),
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}
        />
    );
};

export default PeopleHistoryPage;
