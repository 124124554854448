import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';
import ModalEditRouteLocations from '../components/ModalEditRouteLocations';

const dataURL = '/route_location';

const RouteLocationsPage = () => {
    return(
        <CommonArticlePage
        breadcrumbItems={['Маршрути', 'Локації']}
            dataURL={dataURL}
            customModal={ModalEditRouteLocations}
            columns={[
                columns.title,
                columns.text,
                columns.visitTime,
                columns.getOperations(false),
            ]}
        />
    );
};

export default RouteLocationsPage;
