import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Input, Pagination } from 'antd';

function itemRender(current: any, type: string, originalElement: any) {
    let component: any = null;
    if (type === 'jump-prev') {
        component = <a>...</a>;
    } else if (type === 'jump-next') {
        component = <a>...</a>;
    } else if (type === 'prev') {
        component = <LeftOutlined />;
    } else if (type === 'next') {
        component = <RightOutlined />;
    } else {
        component = originalElement;
    }
    return component;
}
interface IFooterPaginationProps {
    page: number;
    pageSize: number;
    showSizeChanger: boolean;
    total: number;
    onChange: (page: number, pageSize?: number) => any;
}

const FooterPagination = ({
    total,
    pageSize,
    onChange,
    showSizeChanger,
    page,
}: IFooterPaginationProps) => {
    const [value, setValue] = useState(page.toString());
    const handleChange = (page: number, pageSize: number | undefined) => {
        if (pageSize !== pageSize) {
            onChange(1, pageSize);
        } else {
            onChange(page, pageSize);
        }
    };

    const onChangeValue = (e: any) => {
        const v = e.target.value;
        const page = parseInt(v);
        if (!isNaN(page)) {
            setValue(v);
        }
        if (v === '') {
            setValue('');
        }
    };
    const onBlur = () => {
        const page = +value;
        if (page >= 1 && page <= Math.ceil(total / pageSize)) {
            onChange(page, pageSize);
        }
    };

    useEffect(() => {
        setValue(page.toString());
    }, [page]);

    if (total === 0) return null;
    return (
        <div className="footer-pagination">
            <Pagination
                current={page}
                showSizeChanger={showSizeChanger}
                itemRender={itemRender}
                onChange={handleChange}
                pageSize={pageSize}
                total={total}
            />
            <div className="select-page">
                Сторінка
                <Input value={value} onBlur={onBlur} onChange={onChangeValue} />
                із {Math.ceil(total / pageSize)}
            </div>
        </div>
    );
};

export default FooterPagination;
