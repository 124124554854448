import service from './../core/setAxiosOptions';

export const imageService = {
    getImage: (id: string) => service.get(`image/${id}`),
    uploadImage: (file: any) => service.post<{ _id: string}>('image', file, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then(response => response.data),
    deleteImage: (id: string) => service.delete(`image/${id}`),
}