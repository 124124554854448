import React from 'react';
import AboutPage from '../components/AboutPage';

const dataCode = 'developers';

const DevelopersPage = () => {
    return (
        <AboutPage dataCode={dataCode}/>
    )
};

export default DevelopersPage;