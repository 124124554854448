import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IUser } from '../typings/IUser';
import axios from '../core/setAxiosOptions';
import openNotificationWithIcon from '../core/notifications';

interface ISession {
    auth: boolean,
    accessToken: string ;
    user: IUser;
}

interface IAuthContext {
    session: ISession | null;
    setSession: any;
    removeSession: any;
    getCurrentUserRole: any;
}
const AuthContext = React.createContext<IAuthContext>({
    session: null,
    setSession: null,
    removeSession: null,
    getCurrentUserRole: null
});

export const getStorageSession = () => {
    try {
        const storageSession = JSON.parse(localStorage.getItem('persist:session') as any);
        return storageSession.auth && storageSession.accessToken && storageSession.user ? storageSession : null;
    } catch (e) {
        return null;
    }
}

const useAuthContext = () => {
    return useContext(AuthContext);
};

const ProviderAuthContext = ({ children }: any) => { 
    const [session, setStateSession] = useState(getStorageSession());

    const setSession = (session: ISession) => {
        localStorage.setItem('persist:session', JSON.stringify(session));
        setStateSession(session);
    }

    const removeSession = () => {
        localStorage.removeItem('persist:session');
        setStateSession(null);
    }

    const getCurrentUserRole = () : string | null => {
        return session?.user?.role?.code || null;
    }

    const value = useMemo(() => {
        return { session, setSession, removeSession, getCurrentUserRole };
    }, [session]);

    useEffect(() => {
        axios.interceptors.response.use(
            (response: any) => {
                return response;
            },
            (error: any) => {
                if (error && error.hasOwnProperty('response')) {
                    if (error.response && error.response.status) {
                        if (error.response.status === 401) {
                            console.log('unauthorized, logging out ...');
                            openNotificationWithIcon('warning', 'Закінчився час сесії. Виконайте, будь ласка, авторизацію повторно!');
                            removeSession();
                            return Promise.reject(
                                error.response.data ||
                                    error.message ||
                                    'Помилка серверу',
                            );
                        }
                        if (error.response.status === 404) {
                            return Promise.reject(
                                error.response.data ||
                                    error.message ||
                                    'Помилка серверу',
                            );
                        }
                    }
                }
                return Promise.reject(
                    error.response || error.message || 'Помилка серверу',
                );
            },
        );
    }, []);

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export { useAuthContext };
export default ProviderAuthContext;
