import React, {useEffect, useState} from 'react';
import {
    Form,
    Modal,
    Input,
    Button,
    Row,
    Col,
    Select,
    Upload,
    Checkbox
} from 'antd';
import RichTextEditor from './RichTextEditor';
import { REQUIRED_MSG } from '../constants/common';
import { AxiosResponse } from 'axios';
import { imageService } from '../services/imageService';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { IObject3D, LocationDTO } from '../typings/ILocation';
import { ILocationFetchData } from '../typings/IFetchData';
import { AudioUploader } from './AudioUploader';
import useAxios from 'axios-hooks';
import { imageInfoToAntdModel, imageURLToAntdModel } from '../helpers/imageToAntdModel';
import { uploadCustomRequest, updateImagesList, updateAudio } from '../helpers/filesLoadHandling';
import { checkFileSize } from '../helpers/checkFileSize';
import './../assets/scss/styles.scss';

const object3dURL = '/object3d';
const coordinateRules = {
    latitude : {
        min: -90,
        max: 90
    },
    longitude : {
        min: -180,
        max: 180
    }
}

interface IModalEditLocationProps {
    defaultItem: any;
    visible: boolean;
    handleOk: (data: LocationDTO) => Promise<AxiosResponse<ILocationFetchData>>;
    handleCancel: () => void;
    dataURL: string;
}

const ModalEditLocation = ({
    defaultItem,
    visible,
    handleOk,
    handleCancel,
    dataURL
}: IModalEditLocationProps) => {
    const [form] = Form.useForm();

    const [isDuplicateGallery, setIsDuplicateGallery] = useState(defaultItem ? defaultItem.isDuplicateGallery : true);

    const [bgImage, setBgImage] = useState(defaultItem?.bgImage ? [imageURLToAntdModel(defaultItem.bgImage)] : [] as any[]);

    const [image360, setImage360] = useState(defaultItem?.image360 ? [imageURLToAntdModel(defaultItem.image360)] : [] as any[]);

    const [imageGalleryUA, setImageGalleryUA] = useState(defaultItem?.imageGalleryUA ?
        defaultItem.imageGalleryUA.map(image => imageURLToAntdModel(image)) : [] as any[]
    );

    const [imageGalleryEN, setImageGalleryEN] = useState(defaultItem?.imageGalleryEN && !isDuplicateGallery ?
        defaultItem.imageGalleryEN.map(image => imageURLToAntdModel(image)) : [] as any[]
    );

    const [{data, loading, error}, request] = useAxios<IObject3D[]>({
        url: object3dURL,
        method: 'GET'
    });

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            const res: any = {
                ...(defaultItem ? { _id: defaultItem._id } : {}),
                object3d: values.object3d || null,
                titleEN: values.titleEN,
                titleUA: values.titleUA,
                shortTextUA: values.shortTextUA,
                shortTextEN: values.shortTextEN,
                textUA: values.textUA,
                textEN: values.textEN,
                bgImage: bgImage[0]?.url || null,
                image360: image360[0]?.url || null,
                imageGalleryUA: imageGalleryUA?.map(image => image.url) || null,
                imageGalleryEN: (isDuplicateGallery ? imageGalleryUA : imageGalleryEN)?.map(image => image.url) || null,
                isDuplicateGallery: isDuplicateGallery,
                audioUA: values.audioUA?.src || null,
                audioEN: values.audioEN?.src|| null,
                coordinates: values.coordinates,
                isNeedPublicize: !!values.isNeedPublicize,
            };

            const result = await handleOk(res);
            if(result.status === 200) {
                form.resetFields();
            }
        });
    };

    const onCancel = () => {
        form.resetFields();
        handleCancel();
    };

    const handleUploadChange = (listName: string, setList: any) =>
        (info: UploadChangeParam<UploadFile<any>>) => {
            const list = eval(listName);
            if (info.file.status === 'removed') {
                const toUpdate = list.filter(image => image.uid !== info.file.uid);
                setList(toUpdate);
                imageService.deleteImage(info.file.uid).then(() => updateImagesList(defaultItem?._id, listName, toUpdate, dataURL, false));
            } else if (info.file.status) {
                const images = [...list];
                const imageToAdd = imageInfoToAntdModel(info);
                setList(
                    images.some(image => image.uid === info.file.uid) ? 
                        images.map(image => image.uid === info.file.uid ? imageToAdd : image) : [...images, imageToAdd]
                );
            }
        };

    return(
        <Modal
            centered
            title={defaultItem ? 'Редагувати' : 'Створити'}
            visible={visible}
            maskClosable={false}
            onCancel={onCancel}
            bodyStyle={{
                maxHeight: '75vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            footer={
                <Row justify={'end'}>
                    <Button onClick={onCancel}>Вiдмiна</Button>
                    <Button type={'primary'} onClick={onFinish}>Зберегти</Button>
                </Row>
            }
            width={'80%'}
        >
            <Form
                form={form}
                name="basic"
                initialValues={defaultItem ? {
                    ...defaultItem,
                    object3d: defaultItem.object3d && defaultItem.object3d._id,
                    audioUA: { src: defaultItem.audioUA || null },
                    audioEN: { src: defaultItem.audioEN || null },
                    icon: defaultItem?.icon?._id
                } : undefined}
                layout={'vertical'}
            >
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col span={11}>
                        <Form.Item
                            label="3D об'єкт"
                            name="object3d"
                        >
                            <Select allowClear>
                                {data &&
                                data.map(c =>
                                    <Select.Option key={c._id} value={c._id}>{c.titleUA}/{c.titleEN}</Select.Option>
                                )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Row style={{ width: '100%', justifyContent: 'space-around' }}>
                            <Col span={24}>
                                <Form.Item name="isNeedPublicize" valuePropName="checked">
                                    <Checkbox>
                                        Опублiкувати
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Назва статтi (UA)"
                            name="titleUA"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Назва статтi (EN)"
                            name="titleEN"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Короткий опис (UA)"
                            name="shortTextUA"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Короткий опис (EN)"
                            name="shortTextEN"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Текст (UA)"
                            name="textUA"
                            required={true}
                            rules={[
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value === '<p><br></p>') {
                                            callback(REQUIRED_MSG);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]}>
                            <RichTextEditor
                                value={form.getFieldValue('textUA')}
                                setValue={(value) => form.setFieldsValue({
                                    textUA: value
                                })}
                                deleteImage={imageService.deleteImage}
                                saveImage={imageService.uploadImage}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Текст (EN)"
                            name="textEN"
                            required={true}
                            rules={[
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value === '<p><br></p>') {
                                            callback(REQUIRED_MSG);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]}>
                            <RichTextEditor
                                value={form.getFieldValue('textEN')}
                                setValue={(value) => form.setFieldsValue({
                                    textEN: value
                                })}
                                deleteImage={imageService.deleteImage}
                                saveImage={imageService.uploadImage}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Зображення для меню"
                            name="bgImage"
                            required={true}
                            rules={[
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value?.fileList?.length && !bgImage.length) {
                                            callback(REQUIRED_MSG);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]}
                        >
                            <Upload
                                beforeUpload={checkFileSize}
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={bgImage}
                                onChange={handleUploadChange('bgImage', setBgImage)}
                            >
                                <Button disabled={!!bgImage?.length}>Завантажити</Button>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Зображення 360"
                            name="image360"
                        >
                            <Upload
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={image360}
                                onChange={handleUploadChange('image360', setImage360)}
                            >
                                <Button disabled={!!image360?.length}>Завантажити</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Галерея (UA)"
                            name="imageGalleryUA"
                        >
                            <Upload
                                beforeUpload={checkFileSize}
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={imageGalleryUA}
                                onChange={handleUploadChange('imageGalleryUA', setImageGalleryUA)}
                            >
                                <Button disabled={(imageGalleryUA?.length >= 7)}>Завантажити</Button> 
                                <span onClick={e => e.stopPropagation()} style={{color: 'rgb(255, 77, 79)'}} hidden={!(imageGalleryUA?.length >= 7)}>
                                    {' максимум 7 зображень'}
                                </span>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Галерея (EN)"
                            name="imageGalleryEN"
                        >
                            <Upload
                                beforeUpload={checkFileSize}
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={!isDuplicateGallery ? imageGalleryEN : []}
                                onChange={handleUploadChange('imageGalleryEN' ,setImageGalleryEN)}
                            >
                                <div className='upload-checkbox'>
                                    {!isDuplicateGallery && 
                                        <>
                                            <Button disabled={(imageGalleryEN?.length >= 7)}>Завантажити</Button> 
                                            <span onClick={e => e.stopPropagation()} style={{color: 'rgb(255, 77, 79)'}} hidden={!(imageGalleryEN?.length >= 7)}>
                                                {' максимум 7 зображень'}
                                            </span>
                                        </>
                                    }
                                    <span onClick={e => e.stopPropagation()}>
                                        <Checkbox
                                            checked={isDuplicateGallery} 
                                            style={{paddingLeft: !isDuplicateGallery ? '10px' : '0px'}} 
                                            onClick={(e: any) => setIsDuplicateGallery(e.target.checked)}
                                        >
                                            Використовувати галерею з (UA) версії статті
                                        </Checkbox>
                                    </span>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col>
                <Col span={11}>
                        <Form.Item
                            label="Аудіо файл (UA)"
                            name="audioUA"
                        >
                            <AudioUploader 
                                value={form.getFieldValue('audioUA')}
                                setValue={(value) => {
                                    form.setFieldsValue({
                                        audioUA: value
                                    });
                                    updateAudio(defaultItem?._id, 'audioUA', value?.src, dataURL);
                                }}/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Аудіо файл (EN)"
                            name="audioEN"
                        >
                            <AudioUploader
                                value={form.getFieldValue('audioEN')}
                                setValue={(value) => {
                                    form.setFieldsValue({
                                        audioEN: value
                                    });
                                    updateAudio(defaultItem?._id, 'audioEN', value?.src, dataURL);
                                }}/>
                        </Form.Item>
                    </Col>
                    </Row>
                    <Row justify='start'>
                    <Col span={5}>
                        <Form.Item
                            label="Широта"
                            name={['coordinates', 'latitude']}
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        value > coordinateRules.latitude.max || value < coordinateRules.latitude.min ? 
                                            callback(`Діапазон широти від ${coordinateRules.latitude.min} до ${coordinateRules.latitude.max}`) : callback();
                                    }
                                }
                            ]}
                        >
                            <Input addonBefore='N' type='number'/>
                        </Form.Item>
                    </Col>
                    <Col span={5} style={{marginLeft: '4%'}}>
                    <Form.Item
                            label="Довгота"
                            name={['coordinates', 'longitude']}
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        value > coordinateRules.longitude.max || value < coordinateRules.longitude.min ? 
                                            callback(`Діапазон довготи від ${coordinateRules.longitude.min} до ${coordinateRules.longitude.max}`) : callback();
                                    }
                                }
                            ]}
                        >
                            <Input addonBefore='E' type='number'/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    );

};

export default ModalEditLocation;