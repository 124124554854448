import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import openNotificationWithIcon from '../core/notifications';
import { Form, Input, Button, Checkbox, Card, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import image from './../assets/images/CHR-icon.png';
import { useAuthContext } from '../context/AuthContext';

interface IResponseLoginData {
    accessToken?: string;
    auth?: boolean;
    user?: any;
    isPasswordTechnical?: boolean;
}

interface IResponseRefreshPasswordData {
    message: String;
}

const LoginForm = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const location = useLocation();
    const { setSession } = useAuthContext();
    const [userToRefreshPassword, setUserToRefreshPassword] = useState(null);

    const [{ data, error, response }, signIn] = useAxios<IResponseLoginData>(
        {
            url: 'auth/signin',
            method: 'POST',
        },
        { manual: true },
    );

    const [refreshPasswordResponse, refreshPassword] = useAxios<IResponseRefreshPasswordData>(
        {
            url: 'auth/refresh/password',
            method: 'POST',
        },
        { manual: true },
    );

    const onSignIn = async (data: any) => {
        const res = await signIn({ data });
        if (res.status === 200) {
            if (res?.data?.isPasswordTechnical) {
                setUserToRefreshPassword(data.email)
            } else {
                setSession(res.data);
            }
        }
    };

    const onRefreshPassword = async (data: any) => {
        delete data.repeatNewPassword;
        data.email = userToRefreshPassword;

        const res = await refreshPassword({data});
        if (res.status === 200) {
            openNotificationWithIcon('success', 'Пароль успішно змінено');
            form.resetFields();
            setUserToRefreshPassword(null);
        }
    };

    React.useEffect(() => { 
        const error = refreshPasswordResponse?.error as any;
        if (error) {
            switch (error?.status) {
                case 403: 
                    openNotificationWithIcon('warning', 'Перевірте правильність введення "старого" паролю');
                    break;
                default:
                    openNotificationWithIcon('error', 'Помилка зміни "старого" паролю');
            }
        }
    }, [refreshPasswordResponse]);

    React.useEffect(() => {
        if (error) {
            const { data }: any = error;
            if (data && typeof data === 'string') {
                openNotificationWithIcon('error', data);
            } else {
                openNotificationWithIcon('error', 'Помилка авторизації');
            }
        }
    }, [error]);

    React.useEffect(() => {
        if (response) {
            if (response.status === 200 ) {
                if (response?.data?.isPasswordTechnical){
                    openNotificationWithIcon('warning', 'Встановлено технічний пароль, будь ласка, оновіть його');
                    form.resetFields();
                } else if (response.statusText === 'OK'){
                    openNotificationWithIcon('success', 'Авторизація успішна');
                    history.push('/home');
                }
            }
        }
    }, [history, response]); // was: [response]
    
    return (
        <div className="site-card-wrapper">
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col>
                    <Card
                        style={{ width: 400, borderColor: 'black' }}
                        cover={<img alt="chornobyl" src={image} />}
                    >
                            <Form form={form} name="basic" onFinish={ !userToRefreshPassword ? onSignIn : onRefreshPassword}>
                                {!userToRefreshPassword && 
                                    <Form.Item
                                        name="email"
                                        id="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введить будь ласка логін!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Login"
                                            prefix={<UserOutlined />}
                                        />
                                    </Form.Item>
                                }

                                <Form.Item
                                name="password"
                                id="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введіть, будь ласка, пароль!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Password"
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>

                            {!userToRefreshPassword ? 
                                <Form.Item name="remember">
                                    <Checkbox>Запам&apos;ятати мене</Checkbox>
                                </Form.Item>
                            : 
                                <>
                                    <Form.Item
                                            name="newPassword"
                                            id="newPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Введіть, будь ласка, новий пароль!',
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                placeholder="New password"
                                                prefix={<LockOutlined />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                                name="repeatNewPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Підтвердіть, будь ласка, новий пароль!",
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (
                                                                !value ||
                                                                getFieldValue('newPassword') === value
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                'Паролi не спiвпадають!',
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                            <Input.Password placeholder="Repeat password" prefix={<LockOutlined />}/>
                                        </Form.Item>
                                    </>
                                }

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: '100%' }}
                                    >
                                        {userToRefreshPassword ? "Оновити пароль" : "Увійти"}
                                    </Button>
                                </Form.Item>
                            </Form> 
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default LoginForm;
