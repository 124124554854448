const baseURL = process.env.REACT_APP_BASE_URL || 'http://huspi.com:5600/api/';

export function imageURLToAntdModel(imageURL: any) {
    return {
        url: imageURL,
        name: imageURL,
        uid: imageURL.substr(imageURL.lastIndexOf('/') + 1)
    };
}

export function imageInfoToAntdModel(info: any) {
    return {
        uid: info.file?.response?._id || info.file.uid,
        url: info.file?.response?._id ? `${baseURL}image/${info.file.response._id}` : '',
        name: info.file?.response?._id ? `${baseURL}image/${info.file.response._id}` : info.file.uid,
    };
}
