import React from 'react';
import { Form, Modal, Input, Checkbox, Select } from 'antd';
import { IUser } from '../typings/IUser';
import { REQUIRED_MSG, LOGIN_NOT_ALLOWED_MSG } from '../constants/common';
import { IRole } from '../typings/IRole';

const { Option } = Select;

interface IModalEditUserProps {
    defaultUser?: IUser;
    visible: boolean;
    userRoles: any;
    handleOk: any;
    handleCancel: any;
}

const ModalEditUser = ({
    defaultUser,
    handleCancel,
    userRoles,
    handleOk,
    visible,
}: IModalEditUserProps) => {
    const [form] = Form.useForm();

    const onFinish = () => {
        form.validateFields().then(values => {
            delete values.repeatPassword;
            handleOk({ ...defaultUser, ...values });
        });
    };

    const onCancel = () => {
        form.resetFields();
        handleCancel();
    };

    return (
        <Modal
            title={
                defaultUser ? 'Редагувати користувача' : 'Створити користувача'
            }
            visible={visible}
            okText={defaultUser ? 'Зберегти' : 'Створити'}
            cancelText={'Вiдмiна'}
            onCancel={onCancel}
            onOk={onFinish}
            width={'400px'}
        >
            <Form
                form={form}
                name="basic"
                initialValues={{
                    ...defaultUser,
                    role: defaultUser?.role?._id
                }}
                layout={'vertical'}
            >
                <Form.Item
                    label="Ім'я"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: REQUIRED_MSG,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Прізвище"
                    name="surname"
                    rules={[
                        {
                            required: true,
                            message: REQUIRED_MSG,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Логін"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: REQUIRED_MSG,
                        },
                        {
                            pattern: /^\S*$/,
                            message: LOGIN_NOT_ALLOWED_MSG,
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Роль"
                    name="role"
                    rules={[
                        {
                            required: true,
                            message: REQUIRED_MSG,
                        },
                    ]}
                >
                    <Select>
                        {userRoles.map((role: IRole) => <Option value={role._id}>{role.name}</Option>)}
                    </Select>
                </Form.Item>
                {!defaultUser && (
                    <React.Fragment>
                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Повторити пароль"
                            name="repeatPassword"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            'Паролi не спiвпадають!',
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </React.Fragment>
                )}
                <Form.Item name="isActive" valuePropName="checked">
                    <Checkbox>Активувати</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalEditUser;
