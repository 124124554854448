import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Breadcrumb } from 'antd';
import FooterPagination from './FooterPagination';
import {useAuthContext} from '../context/AuthContext';
import useAxios from 'axios-hooks';
import buildQuery from '../core/buildQuery';
import { IImageParams } from '../typings/IImageParams';
import ModalEditArticle from './ModalEditArticle';
import ModalGetDeepLink from './ModalGetDeepLink';


interface ICommonArticleProps {
    deepLink?: string,
    breadcrumbItems: string[],
    isShortTextRequired?: boolean,
    dataURL: string,
    selectURL?: string,
    imageParams?: IImageParams,
    isFake?: boolean,
    columns: any,
    customModal?: any
}

const CommonArticlePage = ({
    deepLink,
    breadcrumbItems,
    isShortTextRequired,
    dataURL,
    selectURL,
    imageParams,
    isFake,
    columns,
    customModal
}: ICommonArticleProps) => {
    const { getCurrentUserRole } = useAuthContext();
    const [visibleDeepLink, setvisibleDeepLink] = useState(false);
    const [dataId, setDataId] = useState(null);
    const [filters, setFilters] = useState({});
    const [visible, setVisible] = useState(false);
    const [editData, setEditData] = useState(null);
    const [pagination, setPagination] = useState({
        page: 1,
        total: 10,
        pageSize: 10,
    });

    const [{ data, loading, response }, request] = useAxios<any>({
        url: dataURL + buildQuery(pagination, filters),
        method: 'GET',
    });

    const refreshTable = () => {
        request({
            url: dataURL + buildQuery(pagination, filters),
            method: 'GET',
        });
    };

    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [filters]);

    const handleDelete = async id => {
        const res = await request({
            url: dataURL,
            method: 'DELETE',
            data: {
                _id: id,
            },
        });
        if(res.status === 200) {
            refreshTable();
        }
    };

    const handlePublicize = async (data: any) => {
        const res = await request({
            url: dataURL,
            method: 'PUT',
            data: {
                _id: data._id,
                isNeedPublicize: !data.isNeedPublicize,
            },
        });
        if(res.status === 200) {
            refreshTable();
        }
    };

    const handleChangePagination = (page, pageSize) => {
        setPagination({ ...pagination, page, pageSize });
    };

    useEffect(() => {
        if (response && response.status === 200) {
            setPagination({ ...pagination, total: response.data.totalDocs });
        }
    }, [response]);

    const handleClickEdit = data => {
        setEditData(data);
        setVisible(true);
    };

    const handleOkEditMedia = async (data: any) => {
        const res = await request({
            method: data._id ? 'PUT' : 'POST',
            data,
            url: dataURL
        });
        if(res.status === 200) {
            refreshTable();
            handleCancelEditMedia();
        }
        return res;
    };

    const handleCancelEditMedia = () => {
        setEditData(null);
        setVisible(false);
        refreshTable();
    };

    const handleGetDeepLink = (id) => {
        setDataId(id);
        setvisibleDeepLink(true);
    };

    const handleCancelDeepLinc = () => {
        setDataId(null);
        setvisibleDeepLink(false);
    };

    const userRole = getCurrentUserRole();
    const media = data ? data.documents : [];
    const Modal = customModal || ModalEditArticle;
    return (
        <div>
            <Row className="toolBar" style={{justifyContent: 'space-between'}}>
                <Col>
                    <Breadcrumb separator=">">
                        {breadcrumbItems.map(item =>
                            <Breadcrumb.Item key={item}>
                                <span className='welcome'>{item}</span>
                            </Breadcrumb.Item>)}
                    </Breadcrumb>
                </Col>
                <Col>
                    <Button onClick={() => handleClickEdit(null)}>Створити</Button>
                </Col>
            </Row>
            <Table
                loading={loading}
                size="small"
                dataSource={media}
                columns={columns.map(column => column({handleGetDeepLink, handleDelete, handleClickEdit, handlePublicize, userRole, filters, setFilters}))}
                pagination={false}
                footer={() => (
                    <FooterPagination
                        {...pagination}
                        showSizeChanger={false}
                        onChange={handleChangePagination}
                    />
                )}
                scroll={{ x: 1300 }}
                rowKey="_id"
            />
            {visible && (
                <Modal
                    imageParams={imageParams}
                    selectURL={selectURL}
                    defaultItem={editData}
                    visible={visible}
                    dataURL={dataURL}
                    isFake={isFake}
                    handleOk={handleOkEditMedia}
                    handleCancel={handleCancelEditMedia}
                    isShortTextRequired={isShortTextRequired}
                />
            )}
            {visibleDeepLink && (
                <ModalGetDeepLink
                    deepLink={`${deepLink}/${dataId}`}
                    visible={visibleDeepLink}
                    onCancel={handleCancelDeepLinc}
                />
            )}
        </div>
    );
};

export default CommonArticlePage;