import React, { useState } from 'react';
import { Layout, Menu, Button, Avatar } from 'antd';
import image from '../assets/images/CHR-icon.png';
import routes from '../routes';
import { Switch, useRouteMatch, Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { mapRoutes, mapMenuItems } from '../utils/mapRoutes';
import { getNameLabel } from '../helpers/homeTools';

const { Header, Content, Sider } = Layout;

const Home = () => {
    const [home] = routes.filter(route => route.key === 'Home');
    const [collapsed, setCollapsed] = useState(false);
    const homeRoutes = home.children || [];
    const { path } = useRouteMatch();
    const history = useHistory();

    const { removeSession, getCurrentUserRole, session } = useAuthContext();

    const { name } = session!.user;

    const handleLogout = () => {
        removeSession();
        history.push('/');
    };

    const onCollapse = () => {
        setCollapsed(c => !c);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                style={{
                    position: 'fixed',
                    overflow: 'auto',
                    height: '100vh',
                    left: 0,
                }}
                theme="light"
                width={230}
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
            >
                <div className="logo">
                    <img
                        src={image}
                        height={'100%'}
                        width={'100%'}
                        alt="logo"
                    />
                </div>
                <Menu mode="inline" style={{paddingBottom: '48px'}}>
                    { mapMenuItems(homeRoutes, getCurrentUserRole(), path) }
                </Menu>
            </Sider>
            <Layout style={{ marginLeft: 230 }}>
                <Header
                    className="header"
                    style={{ padding: 0, backgroundColor: 'white' }}
                >
                    <div style={{ paddingRight: 24, marginLeft: 'auto' }}>
                        <Avatar
                            className='avatar'
                            size="large">
                            {getNameLabel(name || '')}
                        </Avatar>
                        {name &&
                            <span className='welcome'>Ласкаво просимо, <b>{name}</b></span>
                        }
                        <Button
                            size="large"
                            shape="circle"
                            icon={<LogoutOutlined />}
                            onClick={handleLogout}
                        />
                    </div>
                </Header>
                <Content className="content">
                    <Switch>
                        { mapRoutes(homeRoutes, getCurrentUserRole(), path, true) }
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Home;
