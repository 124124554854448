import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';
import { ImageCollectionType } from '../typings/IImageParams';

const guideURL = '/guide';
const deepLink = 'guide';

const GuidePage = () => {
    return(
        <CommonArticlePage
            deepLink={deepLink}
            breadcrumbItems={['Наважитись', 'Обрати гіда']}
            dataURL={guideURL}
            imageParams={{type: ImageCollectionType.single}}
            columns={[
                columns.title,
                columns.shortText,
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}
        />
    );
};

export default GuidePage;