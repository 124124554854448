import React, { useEffect, useState } from 'react';
import {
    Menu, Dropdown, Button, Input, Pagination
} from 'antd';
import buildQuery from '../core/buildQuery';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import service from './../core/setAxiosOptions';
import './../assets/scss/styles.scss';

const { Search } = Input;

const defaultPagination = {
    page: 1, 
    total: 10, 
    pageSize: 10 as any
};

interface DragAndDropSelectProps {
    labelKey: string;
    displayedValue: string | ((item: any) => any);
    displayedDropdownValue: string;
    dictionaryURL: string;
    selected: any;
    setSelected: any;
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#f9fafa' : 'white'
});

const DragAndDropSelect = ({ dictionaryURL, selected = [], setSelected, labelKey, displayedValue, displayedDropdownValue }: DragAndDropSelectProps) => {

    const [dictionary, setDictionary] = useState([] as any[]);
    const [items, setItems] = useState([...selected]); 
    const [dropDownVisible, setDropDownVisible] = useState(false); 
    const [query, setQuery] = useState({
        pagination: defaultPagination,
        filters: {}
    });


    useEffect(() => {
        getDictionary();
    }, [query]);

    useEffect(() => {
        setSelected(items);
    }, [items]);

    const getDictionary = () => {
        service.get(dictionaryURL + buildQuery(query.pagination, query.filters)).then(res => {
            setDictionary(res.data.documents);
            if (query.pagination.total !== res.data.totalDocs){
                setQuery({ ...query, pagination: {...query.pagination, total: res.data.totalDocs} });
            }
        });
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }

        setItems(
            reorder(
                items,
                result.source.index,
                result.destination.index
            )
        );
    };

    const removeItem = (toRemove) => {
        const toUpdate = [...items];
        toUpdate.splice(toUpdate.findIndex(item => item[labelKey] === toRemove[labelKey]), 1);
        setItems(toUpdate);
    }

    const addItem = (toAdd) => {
        setItems(items.concat([toAdd]));
    }
    
    const handleVisibleChange = (flag) => {
        setDropDownVisible(flag);
    }

    const isItemSelected = (item): boolean => {
        return items.some(element => element[labelKey] === item[labelKey]);
    }

    const onPaginationChange = (page, pageSize) => {
        setQuery({ ...query, pagination: {...query.pagination, page, pageSize} });
    };

    const onSearchChange = (event) => {
        setQuery({ filters: {
            ...query.filters, [displayedDropdownValue]: `/${event.target.value}/i`}, 
            pagination: {...query.pagination, page: defaultPagination.page} 
        });
    }

    const selectMenu = (
        <Menu>
            <Menu.Item>
                <Search placeholder="Пошук" allowClear onChange={onSearchChange}/>
            </Menu.Item>
            {dictionary.map(item => {
                    const isSelected = isItemSelected(item);
                    return(
                        <Menu.Item 
                        onClick={isSelected ? () => removeItem(item) : () => addItem(item)} 
                        style={{
                            backgroundColor: isSelected ? '#e6f7fe' : 'white',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div>{item[displayedDropdownValue]}</div> {isSelected ? <CheckOutlined className='select-check-icon'/> : ''}

                        </Menu.Item>
                    );
                }
            )}
            <Menu.Item className="select-pagination-center">
                <Pagination 
                    size="small" 
                    showSizeChanger 
                    showQuickJumper 
                    defaultCurrent={query.pagination.page} 
                    total={query.pagination.total} 
                    pageSize={query.pagination.pageSize} 
                    onChange={onPaginationChange}/>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown 
                trigger={['click']}
                overlay={selectMenu} 
                placement="bottomLeft" 
                arrow={false} 
                visible={dropDownVisible} 
                onVisibleChange={handleVisibleChange}>
                <Button><PlusOutlined/>{' Додати'}</Button>
            </Dropdown>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable1" direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className='select-list'
                        style={getListStyle(snapshot.isDraggingOver)}
                        >
                        {items.map((item, index) => (
                            <Draggable key={item[labelKey]} draggableId={item[labelKey]} index={index}>
                            {(provided, snapshot) => (
                                <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className='select-item'
                                style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                                >
                                    { displayedValue instanceof Function ? displayedValue(item) : <div>{item[displayedValue]}</div> }
                                    <div onClick={() => removeItem(item)} className="select-remove-div"><CloseOutlined className="select-vertical-center"/></div>
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default DragAndDropSelect;
