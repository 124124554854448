import React, {useEffect, useState} from 'react';
import {
    Form,
    Modal,
    Input,
    Button,
    Checkbox,
    Row,
    Col,
    Popconfirm,
    Select,
    Upload,
    Tooltip,
    DatePicker
} from 'antd';
import RichTextEditor from './RichTextEditor';
import { REQUIRED_MSG, PUBLICIZE_CONFIRM, MAX_IMG_COUNT, PUBLISH_DATE_TIME_FORMAT } from '../constants/common';
import { AxiosResponse } from 'axios';
import { imageService } from '../services/imageService';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import service from './../core/setAxiosOptions';
import { MobileOutlined } from '@ant-design/icons';
import ModalMobilePreview from './ModalMobilePreview';
import { imageInfoToAntdModel, imageURLToAntdModel } from '../helpers/imageToAntdModel';
import { uploadCustomRequest, updateImagesList } from '../helpers/filesLoadHandling';
import { checkFileSize } from '../helpers/checkFileSize';
import { IImageParams } from '../typings/IImageParams';
import { ImageCollectionType } from '../typings/IImageParams';
import moment from 'moment';
import './../assets/scss/styles.scss';

const selectFields = {
    category: 'category',
    icon: 'icon'
};

interface IModalEditArticleProps {
    isShortTextRequired?: boolean,
    imageParams?: IImageParams,
    selectURL?: string,
    isFake?: boolean,
    dataURL: string,
    defaultItem: any | null,
    visible: boolean;
    handleOk: (data: any) => Promise<AxiosResponse<any>>,
    handleCancel: () => void
};

const ModalEditArticle: React.FC<IModalEditArticleProps> = ({
    isShortTextRequired = true,
    imageParams,
    selectURL,
    isFake,
    defaultItem,
    dataURL,
    visible,
    handleCancel,
    handleOk
}) => {
    const selectField =  isFake ? selectFields.icon : selectFields.category;

    const [form] = Form.useForm();

    const [isDuplicateImage, setIsDuplicateImage] = useState(defaultItem ? defaultItem.isDuplicateImage : true);

    const [imagesUA, setImagesUA] = useState(defaultItem?.imagesUA ?
        defaultItem.imagesUA.map(image => imageURLToAntdModel(image)) : [] as any[]
    );

    const [imagesEN, setImagesEN] = useState(defaultItem?.imagesEN && !isDuplicateImage ?
        defaultItem.imagesEN.map(image => imageURLToAntdModel(image)) : [] as any[]
    );

    const [isPublish, setIsPublish] = useState(!!defaultItem?.isNeedPublicize);

    const [selectData, setSelectData] = useState([] as any[]);

    const [previewVisible, setPreviewVisible] = useState(false);

    const togglePreviewModal = () => {
        setPreviewVisible(!previewVisible);
    }    

    useEffect(() => {
        if (selectURL) {
            getSelectData();
        }
    }, []);

    const getSelectData = () => {
        service.get(selectURL!).then(res => {
            setSelectData(res.data);
        });
    };

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            const res: any = {
                ...(defaultItem ? { _id: defaultItem._id } : {}),
                ...(selectURL ? { [selectField]: values[selectField] } : {}),
                titleEN: values.titleEN,
                titleUA: values.titleUA,
                shortTextUA: values.shortTextUA,
                shortTextEN: values.shortTextEN,
                textUA: values.textUA,
                textEN: values.textEN,
                imagesUA: imagesUA?.map(image => image.url) || null,
                imagesEN: (isDuplicateImage ? imagesUA : imagesEN)?.map(image => image.url) || null,
                isDuplicateImage: isDuplicateImage,
                isNeedPublicize: isPublish,
                isTop: values.isTop,
                ...(isPublish ? { publicizeDate: values.publicizeDate ? moment(values.publicizeDate).format(PUBLISH_DATE_TIME_FORMAT) : new Date() } : {}),
            };

            const result = await handleOk(res);

            if(result.status === 200) {
                form.resetFields();
            }
        });
    };

    const onCancel = () => {
        form.resetFields();
        handleCancel();
    };

    const handleUploadChange = (listName: string, setList: any) =>
        (info: UploadChangeParam<UploadFile<any>>) => {
            const list = eval(listName);
            if (info.file.status === 'removed') {
                const toUpdate = list.filter(image => image.uid !== info.file.uid);
                setList(toUpdate);
                imageService.deleteImage(info.file.uid).then(() => updateImagesList(defaultItem?._id, listName, toUpdate, dataURL, false));
            } else if (info.file.status) {
                const images = [...list];
                const imageToAdd = imageInfoToAntdModel(info);
                setList(
                    images.some(image => image.uid === info.file.uid) ? 
                        images.map(image => image.uid === info.file.uid ? imageToAdd : image) : [...images, imageToAdd]
                );
            }
        };

    const isRenderingImageUploader = imageParams?.type !== ImageCollectionType.none;
    const isSingleImage = imageParams?.type === ImageCollectionType.single;

    return(
        <Modal
            maskClosable={false}
            centered
            title={
            <>
                {defaultItem ? 'Редагувати' : 'Створити'}
                <Tooltip title="Попередній перегляд" placement="left">
                    <MobileOutlined onClick={togglePreviewModal} style={{ float: 'right', paddingRight: '30px', paddingTop: '4px'}}/>
                </Tooltip>
            </>}
            visible={visible}
            onCancel={onCancel}
            bodyStyle={{
                maxHeight: '75vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            footer={
                <Row justify='end'>
                    <Col>
                        <Button onClick={onCancel}>Вiдмiна</Button>
                        {isPublish ? (
                            <Popconfirm
                                title={PUBLICIZE_CONFIRM}
                                onConfirm={onFinish}
                                okText="Так"
                                cancelText="Нi"
                            >
                                <Button type={'primary'}>
                                    {defaultItem ? 'Зберегти' : 'Створити'}
                                </Button>
                            </Popconfirm>
                        ) : (
                            <Button type={'primary'} onClick={onFinish}>
                                {defaultItem ? 'Зберегти' : 'Створити'}
                            </Button>
                        )}
                    </Col>
                </Row>
            }
            width={'80%'}
        >
            <Form
                form={form}
                name="basic"
                initialValues={defaultItem ? {
                    ...defaultItem,
                    publicizeDate: defaultItem?.publicizeDate ? moment(defaultItem.publicizeDate) : null,
                    ...(defaultItem[selectField] ? {[selectField]: defaultItem[selectField]._id} : {})
                } : undefined}
                layout={'vertical'}
            >
                <Row style={{ justifyContent: 'space-around' }}>
                    {selectURL &&
                        <Col span={11}>
                            <Form.Item
                                label={!isFake ? 'Категорiя' : 'Іконка'}
                                name={selectField}
                                {...(!isFake ? {
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_MSG,
                                        }
                                    ]
                                } : {} )}
                            >
                                <Select>
                                    {selectData &&
                                        selectData.map(c =>
                                            <Select.Option key={c._id} value={c._id}>{c.titleUA}/{c.titleEN}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col span={selectURL ? 11 : 24} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Row style={{ width: '100%', justifyContent: 'space-around' }}>

                            <Col span={isPublish ? 7 : 11}>
                                <Form.Item name="isTop" valuePropName="checked">
                                    <Checkbox>
                                        В топі
                                    </Checkbox>
                                </Form.Item>
                            </Col>

                            <Col span={isPublish ? 7 : 11}>
                                <Form.Item name="isNeedPublicize" valuePropName="checked">
                                    <Checkbox
                                        checked={isPublish}
                                        onChange={e => setIsPublish(e.target.checked)}
                                    >
                                        Опублiкувати
                                    </Checkbox>
                                </Form.Item>
                            </Col>

                            {isPublish && <Col span={7}>
                                <Form.Item name="publicizeDate">
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder='Оберіть дату публікації'/>
                                </Form.Item>
                            </Col>}

                        </Row>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Назва статтi (UA)"
                            name="titleUA"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Назва статтi (EN)"
                            name="titleEN"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Короткий опис (UA)"
                            name="shortTextUA"
                            rules={[
                                {
                                    required: isShortTextRequired,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Короткий опис (EN)"
                            name="shortTextEN"
                            rules={[
                                {
                                    required: isShortTextRequired,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Текст (UA)"
                            name="textUA"
                            required={true}
                            rules={[
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value === '<p><br></p>') {
                                            callback(REQUIRED_MSG);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]}>
                            <RichTextEditor
                                value={form.getFieldValue('textUA')}
                                setValue={(value) => form.setFieldsValue({
                                    textUA: value
                                })}
                                deleteImage={imageService.deleteImage}
                                saveImage={imageService.uploadImage}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Текст (EN)"
                            name="textEN"
                            required={true}
                            rules={[
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value === '<p><br></p>') {
                                            callback(REQUIRED_MSG);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]}>
                            <RichTextEditor
                                value={form.getFieldValue('textEN')}
                                setValue={(value) => form.setFieldsValue({
                                    textEN: value
                                })}
                                deleteImage={imageService.deleteImage}
                                saveImage={imageService.uploadImage}
                            />
                        </Form.Item>
                    </Col>

                    {isRenderingImageUploader && <>
                    <Col span={11}>
                        <Form.Item
                            label="Зображення для статті (UA)"
                            name="imagesUA"
                            required={imageParams?.required}
                            {
                               ...(imageParams?.required ? {
                                   rules: [
                                    {
                                        validator: (rule: any, value: any, callback: any) => {
                                            if (!value?.fileList?.length && !imagesUA.length) {
                                                callback(REQUIRED_MSG);
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                   ]
                               } : {}) 
                            }
                        >
                            <Upload
                                beforeUpload={checkFileSize}
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={imagesUA}
                                onChange={handleUploadChange('imagesUA', setImagesUA)}
                            >
                                <Button disabled={(imagesUA?.length >= (Number(isSingleImage) || MAX_IMG_COUNT))}>Завантажити</Button> 
                                <span onClick={e => e.stopPropagation()} style={{color: '#ff4d4f'}} hidden={!(imagesUA?.length >= MAX_IMG_COUNT) || isSingleImage}>
                                    {` максимум ${MAX_IMG_COUNT} зображень`}
                                </span>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Зображення для статті (EN)"
                            name="imagesEN"
                            required={imageParams?.required}
                            {
                                ...(imageParams?.required && !isDuplicateImage ? {
                                    rules: [
                                     {
                                         validator: (rule: any, value: any, callback: any) => {
                                             if (!value?.fileList?.length && !imagesEN.length) {
                                                 callback(REQUIRED_MSG);
                                             } else {
                                                 callback();
                                             }
                                         }
                                     }
                                    ]
                                } : {}) 
                             }
                        >
                         <Upload
                                beforeUpload={checkFileSize}
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={!isDuplicateImage ? imagesEN : []}
                                onChange={handleUploadChange('imagesEN', setImagesEN)}
                            >
                                <div className='upload-checkbox'>
                                    {!isDuplicateImage && <>
                                        <Button disabled={(imagesEN?.length >= (Number(isSingleImage) || MAX_IMG_COUNT))}>Завантажити</Button> 
                                        <span onClick={e => e.stopPropagation()} style={{color: '#ff4d4f'}} hidden={!(imagesEN?.length >= MAX_IMG_COUNT) || isSingleImage}>
                                            {` максимум ${MAX_IMG_COUNT} зображень`}
                                        </span>
                                    </>}
                                    <span onClick={e => e.stopPropagation()}>
                                        <Checkbox
                                            checked={isDuplicateImage} 
                                            style={{paddingLeft: !isDuplicateImage ? '10px' : '0px'}} 
                                            onClick={(e: any) => setIsDuplicateImage(e.target.checked)}
                                        >
                                            Використовувати зображення з (UA) версії статті
                                        </Checkbox>
                                    </span>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col></>}

                </Row>
            </Form>
            {previewVisible && (
                <ModalMobilePreview
                    visible={previewVisible}
                    item={{
                        titleEN: form.getFieldValue('titleEN'),
                        titleUA: form.getFieldValue('titleUA'),
                        textUA: form.getFieldValue('textUA'),
                        textEN: form.getFieldValue('textEN'),
                        imageUA: imagesUA[0]?.url,
                        imageEN: !isDuplicateImage ? imagesEN[0]?.url : imagesUA[0]?.url
                    }}
                    handleCancel={togglePreviewModal}
                />
            )}
        </Modal>
    );

};

export default ModalEditArticle;