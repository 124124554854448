/* eslint-disable react/display-name */
import React from 'react';
import { INews, IFakeNews } from '../typings/INews';
import { getUserFullName } from '../helpers/getUserFullName';
import { Tag, Row, Tooltip, Button, Popconfirm } from 'antd';
import Icon, { EditOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { CANCEL_PUBLICIZE_CONFIRM, PUBLICIZE_CONFIRM } from '../constants/common';
import { getDateTimeFormat } from '../helpers/getDateTimeFormat';
import { ReactComponent as PublishIcon } from '../assets/images/publish-24px.svg';
import { ReactComponent as UnpublishIcon } from '../assets/images/unpublished-24px.svg';
import { userRoles } from '../constants/userRoles';
import { cropString } from '../helpers/cropString';
import { stripHTML } from '../helpers/stripHTML';

import { Search, searchTypes } from '../components/Search';

export const title = ({filters, setFilters}) => ({
    title: 'Назва',
    dataIndex: 'titleUA',
    width: 150,
    ...Search('titleUA', filters, setFilters)
});

export const visitTime = () => ({
    title: 'Час відвідування',
    dataIndex: 'time',
    width: 150
});

export const shortText = ({filters, setFilters}) => ({
    title: 'Короткий опис',
    dataIndex: 'shortTextUA',
    width: 350,
    ...Search('shortTextUA', filters, setFilters)
});

export const text = ({filters, setFilters}) => ({
    title: 'Текст',
    dataIndex: 'textUA',
    width: 600,
    render: (htmlTemplateText: string) => {
        return cropString(stripHTML(htmlTemplateText), 150);
    },
    ...Search('textUA', filters, setFilters)
});

export const category = (dictionaryName) => ({filters, setFilters}) => ({
    title: 'Категорiя',
    dataIndex: ['category', 'titleUA'],
    width: 130,
    ...Search('category', filters, setFilters, searchTypes.dictionary, dictionaryName)
});

export const routeLocation = () => ({
    title: 'Локації',
    dataIndex: 'locations',
    width: 130,
    render: (locations: any[]) => (locations || []).map(location => location.titleUA).join(', '),
});

export const isNeedPublicize = ({filters, setFilters}) => ({
    title: 'Опублiковано',
    dataIndex: 'isNeedPublicize',
    width: 130,
    ...Search('isNeedPublicize', filters, setFilters, searchTypes.boolean),
    render: (isNeedPublicize: boolean) => {
        return isNeedPublicize ? (
            <Tag color="green">Так</Tag>
        ) : (
            <Tag color="red">Нi</Tag>
        );
    }
});

export const isTop = ({filters, setFilters}) => ({
    title: 'В топі',
    dataIndex: 'isTop',
    width: 80,
    ...Search('isTop', filters, setFilters, searchTypes.boolean),
    render: (isNeedPublicize: boolean) => {
        return isNeedPublicize ? (
            <Tag color="green">Так</Tag>
        ) : (
            <Tag color="red">Нi</Tag>
        );
    }
});

export const createdAuthor = () => ({
    title: 'Автор створення',
    dataIndex: 'createdAuthor',
    width: 130,
    render: (author: INews['createdAuthor']) => author && getUserFullName(author)
});

export const createdDate = () => ({
    title: 'Дата створення',
    dataIndex: 'createdDate',
    width: 100,
    className: 'ant-column-centered',
    render: (date: INews['createdDate']) => getDateTimeFormat(date)
});

export const publicizeAuthor = () => ({
    title: 'Автор публікації',
    dataIndex: 'publicizeAuthor',
    width: 130,
    render: (author: INews['publicizeAuthor']) => author && getUserFullName(author)
});

export const publicizeDate = () => ({
    title: 'Дата публікації',
    dataIndex: 'publicizeDate',
    width: 100,
    className: 'ant-column-centered',
    render: (date: INews['publicizeDate']) => date && getDateTimeFormat(date)
});

export const object3d = () => ({
    title: '3D Об\'єкт',
    dataIndex: ['object3d', 'titleUA'],
    width: 130
});

export const getOperations = (showPublicizeButton: boolean = true) => ({handleGetDeepLink, handleDelete, handleClickEdit, handlePublicize, userRole}) => ({
    title: 'Дії',
    key: 'operation',
    fixed: 'right' as 'right',
    width: 150,
    render: (news: INews) => {
        return (
            <Row justify={'space-around'}>
                <Tooltip title="Редагувати">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        size={'middle'}
                        onClick={() => handleClickEdit(news)}
                    />
                </Tooltip>
                {showPublicizeButton && <Tooltip title={news.isNeedPublicize ? 'Відмінити публікацію' : 'Опублікувати'} >
                    <Popconfirm
                        placement="topRight"
                        title={news.isNeedPublicize ? CANCEL_PUBLICIZE_CONFIRM : PUBLICIZE_CONFIRM}
                        onConfirm={() => handlePublicize(news)}
                        okText="Так"
                        cancelText="Нi"
                    >
                        <Button 
                            type="primary"
                            icon={news.isNeedPublicize ?
                                <Icon component={UnpublishIcon} /> : <Icon component={PublishIcon} />}
                            size={'middle'}
                        />
                    </Popconfirm>
                </Tooltip>}
                
                {showPublicizeButton && ( 
                <Tooltip title="Отримати посилання">
                    <Button
                        type="primary"
                        icon={<LinkOutlined />}
                        size={'middle'}
                        onClick={() => handleGetDeepLink(news._id)}
                    />
                </Tooltip>
                )}

                {userRole !== userRoles.publisher &&

                <Tooltip title="Видалити">
                    <Popconfirm
                        placement="topRight"
                        title="Ви дійсно бажаєте видалити запис?"
                        onConfirm={() => handleDelete(news._id)}
                        okText="Так"
                        cancelText="Нi"
                    >
                        <Button
                            type="primary"
                            icon={<DeleteOutlined />}
                            size={'middle'}
                        />
                    </Popconfirm>
                </Tooltip>
                }
            </Row>
        );
    }
});