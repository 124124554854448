import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Row, Switch, Table, Tag, Tooltip,
    Input, Space, Col} from 'antd';
import FooterPagination from '../components/FooterPagination';
import useAxios from 'axios-hooks';
import { IUserFetchData } from '../typings/IFetchData';
import ModalEditUser from '../components/ModalEditUser';
import ModalPasswordEditor from '../components/ModalPasswordEditor';
import {
    CloseOutlined,
    CheckOutlined,
    EditOutlined,
    KeyOutlined,
} from '@ant-design/icons';
import buildQuery from '../core/buildQuery';
import { IUser } from '../typings/IUser';
import { AxiosRequestConfig } from 'axios';
import { Search } from './../components/Search';
import openNotificationWithIcon from '../core/notifications';

const { Column } = Table;

interface ColumnActiveProps {
    isActive: boolean;
    id: string;
    refresh: () => any;
}

const ColumnActive = ({ isActive, id, refresh }: ColumnActiveProps) => {
    const [active, setActive] = useState(isActive);

    const [{}, request] = useAxios(
        {
            url: 'user/active',
            method: 'PUT',
        },
        {
            manual: true,
        },
    );

    useEffect(() => {
        setActive(isActive);
    },[isActive]);

    const handleToggle = () => {
        setActive(s => !s);
        request({
            data: {
                _id: id,
            },
        })
            .then(refresh)
            .catch(() => {
                setActive(s => !s);
            });
    };

    return (
        <Popconfirm
            title={`${active ? 'Деактивувати' : 'Активувати'} користувача?`}
            onConfirm={handleToggle}
            okText="Так"
            cancelText="Нi"
        >
            <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={active}
            />
        </Popconfirm>
    );
};

const UsersPage = () => {
    const [filters, setFilters] = useState({});
    const [visible, setVisible] = useState(false);
    const [visiblePasswordEditor, setVisiblePasswordEditor] = useState(false);
    const [editUser, setEditUser] = useState(undefined as IUser | undefined);
    const [pagination, setPagination] = useState({
        page: 1,
        total: 10,
        pageSize: 10,
    });

    const [{ data, loading, response }, request] = useAxios<IUserFetchData>({
        url: 'user/' + buildQuery(pagination, filters),
        method: 'GET',
    });

    const [roles , getUserRoles] = useAxios<IUserFetchData>({
        url: 'user_role',
        method: 'GET',
    });

    const handleChangePagination = (page, pageSize) => {
        setPagination(state => {
            const p = { ...state, page, pageSize };
            refreshTable(p);
            return p;
        });
    };

    useEffect(() => {
        if (response && response.status === 200) {
            setPagination(p => {
                const page = (p.page-1)*p.pageSize >= response.data.totalDocs ? 1 : p.page;
                return { ...p, page: page, total: response.data.totalDocs };
            });
        }
    }, [response]);

    const handleRestorePassword = data => {
        setEditUser(data);
        setVisiblePasswordEditor(true);
    };

    const refreshTable = pagination => {
        return request({
            url: 'user' + buildQuery(pagination, filters),
            method: 'GET',
        });
    };

    const handleClickEdit = data => {
        setEditUser(data);
        setVisible(true);
    };

    const handleOkEditUser = async (data: IUser) => {
        try {
            let method: AxiosRequestConfig['method'] = data._id ? 'put': 'post';
    
            const res = await request({
                method,
                data,
                url: 'user/',
            });
            if (res.status === 200) {
                handleCancelModals();
                refreshTable(pagination);
            }
        } catch (e) {
            if(e.data === 'This email already exists!'){
                openNotificationWithIcon('warning', 'Нажаль обраний логін вже існує');
            }
        }
    };

    const handleCancelModals = () => {
        setEditUser(undefined);
        setVisible(false);
        setVisiblePasswordEditor(false);
    };

    const users = data ? data.users : [];

    return (
        <div>
            <Row className="toolBar" style={{justifyContent: 'space-between'}}>
                <Col>
                    <span className='welcome'>Користувачі</span>
                </Col>
                <Col>
                    <Button onClick={() => handleClickEdit(null)}>Створити</Button>
                </Col>
            </Row>
            <Table
                loading={loading}
                size={'small'}
                dataSource={users}
                pagination={false}
                footer={() => (
                    <FooterPagination
                        {...pagination}
                        showSizeChanger={false}
                        onChange={handleChangePagination}
                    />
                )}
                scroll={{ x: 1000, y: 716 }}
            >
                <Column title="Ім'я" dataIndex="name" key="name" />
                <Column title="Прізвище" dataIndex="surname" key="surname" />
                <Column
                    title="Логін"
                    dataIndex="email"
                    key="email"
                    {...Search('email', filters, setFilters)}
                />
                <Column
                    title="Активний"
                    dataIndex="isActive"
                    key="isActive"
                    width={100}
                    render={(_, item: IUser) => {
                        return (
                            <ColumnActive
                                isActive={item.isActive}
                                id={item._id}
                                refresh={() => refreshTable(pagination)}
                            />
                        );
                    }}
                />
                <Column
                    title="Дії"
                    key="operation"
                    fixed={'right'}
                    width={100}
                    render={row => {
                        return (
                            <Row justify={'space-around'}>
                                <Tooltip title="Редагувати">
                                    <Button
                                        type="primary"
                                        icon={<EditOutlined />}
                                        size={'middle'}
                                        onClick={() => handleClickEdit(row)}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title="Встановити пароль"
                                    placement="topRight"
                                >
                                    <Button
                                        type="primary"
                                        icon={<KeyOutlined />}
                                        size={'middle'}
                                        onClick={() =>
                                            handleRestorePassword(row)
                                        }
                                    />
                                </Tooltip>
                            </Row>
                        );
                    }}
                />
            </Table>
            {visible && (
                <ModalEditUser
                    defaultUser={editUser}
                    visible={visible}
                    userRoles={roles?.data}
                    handleOk={handleOkEditUser}
                    handleCancel={handleCancelModals}
                />
            )}
            {
                visiblePasswordEditor && (
                    <ModalPasswordEditor
                        defaultUser={editUser}
                        visible={visiblePasswordEditor}
                        handleOk={handleOkEditUser}
                        handleCancel={handleCancelModals}
                    />
                )
            }
        </div>
    );
};

export default UsersPage;
