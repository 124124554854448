import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import './../assets/scss/styles.scss';
import { audioService } from '../services/audioService';
import { CloseCircleOutlined } from '@ant-design/icons';

const baseURL = process.env.REACT_APP_BASE_URL || 'http://huspi.com:5600/api/';

interface AudioUploaderProps {
    value: string;
    setValue: any;
};

export const AudioUploader = ({ value, setValue }: AudioUploaderProps) => {
    let inputRef = React.createRef() as any;
    const [audio, setAudio] = useState(value as any);

    useEffect(() => {
        setValue(audio);
    }, [audio]);

    const uploadAudio = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e?.target?.files?.length){
            return;
        }

        const fData = new FormData();
        fData.append('file', e?.target?.files[0]);
        
        audioService.uploadAudio(fData)
            .then((image) => {
                const audio = new Audio(`${baseURL}audio/${image._id}`);
                audio.addEventListener('loadedmetadata', (e: any) => {
                    setAudio({src: image._id, time: e?.target?.duration})
                });
            })
            .catch((e) => {});
        
    };

    const deleteAudio = () => {
        audioService.deleteAudio(audio.src).then(() => {
            setAudio({src: null, time: null});
        });
    }

    const inputClick = () => {
        inputRef.click();
    }

    return (
        <>
            {!audio?.src && 
                <>
                    <Button onClick={inputClick}> Завантажити</Button>
                    <input type="file" id="file" name="file" accept="audio/mpeg,audio/mp4,audio/ogg" onChange={uploadAudio} ref={ref => inputRef = ref} hidden/>
                </>
            }
            {audio?.src &&
                <>
                    <audio controls src={`${baseURL}audio/${audio?.src}`} preload="auto"/>
                    <CloseCircleOutlined onClick={deleteAudio} style={{verticalAlign: 'top', position: 'relative'}}/>
                </>
            }
        </>
    );
};
