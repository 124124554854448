import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';

const articleURL = '/article';
const categoryDictionary = 'article_category';
const deelLink = 'detail/news';

const NewsPage = () => {
    return(
        <CommonArticlePage
            deepLink={deelLink}
            breadcrumbItems={['Інформація', 'Новини']}
            isShortTextRequired={false}
            dataURL={articleURL}
            selectURL={categoryDictionary}
            columns={[
                columns.title,
                columns.shortText,
                columns.category(categoryDictionary),
                columns.isNeedPublicize,
                columns.isTop,
                columns.createdDate,
                columns.publicizeAuthor,
                columns.publicizeDate,
                columns.getOperations(),
            ]}
        />
    );
};

export default NewsPage;
