import React, {useState} from 'react';
import {Modal, Input, Button, Row} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';

interface IModalGetDeepLinkProps {
    deepLink: string,
    visible: boolean,
    onCancel: any,
}

const baseDeepLink = process.env.MOBILE_APP_BASE_DEEP_LINK || 'chrnmobile://info/';

const ModalGetDeepLink = ({deepLink, visible, onCancel}: IModalGetDeepLinkProps) => {
    const [copied, setCopied] = useState(false);
    const onCopy = () => {
        setCopied(true);
    };
    
    return(
        <Modal 
            title='Посилання на публікацію у мобільному додатку'
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button onClick={() => onCancel()} type='primary'>OK</Button>
            ]}
        >
            <Row style={{justifyContent: 'center'}}>
                <Input value={baseDeepLink + deepLink} disabled style={{width: '70%'}}/>
                <CopyToClipboard text={baseDeepLink + deepLink}>
                    <Button onClick={() => onCopy()}>Скопіювати</Button>
                </CopyToClipboard>
                {copied && (
                    <span style={{color: 'green'}}>Скопійовано</span>
                )}
            </Row>
        </Modal>
    );
};

export default ModalGetDeepLink;