import React from 'react';
import {Modal, Form, Input} from 'antd';
import { IUser } from '../typings/IUser';

interface IModalPasswordEditorProps {
    defaultUser?: IUser;
    visible: boolean;
    handleOk: any;
    handleCancel: any;
}

const ModalPasswordEditor = ({
    defaultUser,
    visible,
    handleOk,
    handleCancel
}: IModalPasswordEditorProps) => {
    const [form] = Form.useForm();

    const onCancel = () => {
        form.resetFields();
        handleCancel();
    };

    const onFinish = () => {
        form.validateFields().then(values => {
            form.resetFields();
            delete values.repeatPassword;
            handleOk({
                ...defaultUser,
                ...values,
                isPasswordTechnical: true,
                role: defaultUser?.role?._id,
            });
        });
    };

    return (
        <Modal
            title={`Встановити пароль користувачу ${defaultUser && defaultUser.name}`}
            visible={visible}
            okText={'Зберегти'}
            cancelText={'Вiдмiна'}
            onCancel={onCancel}
            onOk={onFinish}
            width={'400px'}
        >
            <Form
                form={form}
                name="basic"
                layout={'vertical'}
            >
                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Це поле обов'язкове",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Повторити пароль"
                    name="repeatPassword"
                    rules={[
                        {
                            required: true,
                            message: "Це поле обов'язкове",
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    'Паролi не спiвпадають!',
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalPasswordEditor