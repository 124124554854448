import Axios from 'axios';
import { configure } from 'axios-hooks';
import { getStorageSession } from '../context/AuthContext';

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || 'http://huspi.com:5600/api/',
});

axios.interceptors.request.use(
    (config: any) => {
        const session = getStorageSession();

        const token = session?.accessToken;
        if (token) {
            config.headers['Authorization'] = `${token}`;
        }
        return config;
    },
    (error: any) => {
        // handle the error
        return Promise.reject(error);
    },
);

configure({
    axios,
});
export default axios;
