import React from 'react';
import AboutPage from '../components/AboutPage';

const dataCode = 'partners';

const PartnersPage = () => {
    return (
        <AboutPage dataCode={dataCode}/>
    )
};

export default PartnersPage;