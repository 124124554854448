import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import routes from './routes';
import './App.css';
import './assets/scss/styles.scss';
import { useAuthContext } from './context/AuthContext';
import { mapRoutes } from './utils/mapRoutes';
import NotFound from './views/NotFound';

function App() {
    const { session, getCurrentUserRole } = useAuthContext();
    return (
        <Router>
            <Switch>
                { mapRoutes(routes, getCurrentUserRole()) }
                <Redirect from="/" to="/home" exact /> 
                <Route component={(props: any) => session?.auth ? <NotFound {...props} /> : <Redirect to="/login" exact/>} />
            </Switch>
        </Router>
    );
}

export default App;
