import React, { useState, useEffect  } from 'react';
import { Button, Input, Space, Radio, Select} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import service from './../core/setAxiosOptions';

const { Option } = Select;

export enum searchTypes {
    text = 'text',
    date = 'date',
    dictionary = 'dictionary',
    boolean = 'boolean',
}

export interface filtersObject {
    [key: string]: any
}

export const parseFilters = (filters: filtersObject = {}) => {
    return !Object.keys(filters).length ? '' : `&filter=` + encodeURIComponent( 
        (Object.entries(filters)).reduce((result, [key, value]) => {
            result.push(`${key}=${value}`)
            return result;
        }, [] as string[]).join('&'));
}

export const Search = (dataIndex: string, filters: filtersObject, setFilters: any, type?: searchTypes, dictName?: string) => {
    const [value, setValue] = useState('' as any);
    const [dictionary, setDictionary] = useState([] as any[]);

    const getDictionary = () => {
        if (dictName) {
            service.get(dictName).then(res => {
                setDictionary(res.data);
            });
        }
    };

    useEffect(() => {
        getDictionary()
    }, []);

    const handleSearch = (containts: boolean = false) => {
        setFilters({
            ...filters,
            [dataIndex]: !containts ? value : `/${value}/i`
        });
    };

    const handleReset = (clearFilters) => {
        const newFilters = {...filters}
        delete newFilters[dataIndex];

        setFilters(newFilters);
        setValue('')
        clearFilters();
    };

    const getFilterDropdown = () => {
        switch(type){
            case searchTypes.dictionary:
                return ({ setSelectedKeys, selectedKeys, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Select 
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                            value={value}
                            onChange={value => setValue(value)}>
                            {dictionary.map(element => <Option value={element._id}>{element.titleUA}</Option>)}
                        </Select>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch()}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button>
                            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>
                        </Space>
                    </div>
                );
            case searchTypes.boolean:
                return ({ setSelectedKeys, selectedKeys, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Radio.Group 
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                            value={value}
                            onChange={e => setValue(e.target.value)}>
                                <Radio value={true}>Так</Radio>
                                <Radio value={false}>Ні</Radio>
                        </Radio.Group>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch()}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button>
                            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>
                        </Space>
                    </div>
                );
            default: 
                return ({ setSelectedKeys, selectedKeys, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder={`Пошук`}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            onPressEnter={() => handleSearch(true)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(true)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button>
                            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>
                        </Space>
                    </div>
                );
        }
    }

    return {
        filterDropdown: getFilterDropdown(),
        filterIcon: () => <SearchOutlined style={{ color: filters[dataIndex] !== undefined  ? '#1890ff' : undefined }} />
    }
};
