import React from 'react';
import Login from './views/Login';
import Users from './views/UsersPage';
import Home from './views/Home';
import { UserOutlined, BookOutlined, CommentOutlined, InfoCircleOutlined, HourglassOutlined,
    AlertOutlined, FileProtectOutlined, BankOutlined, HddOutlined, TeamOutlined, TrophyOutlined,
    CameraOutlined, SoundOutlined, FireOutlined, ExceptionOutlined, UserSwitchOutlined, DollarCircleOutlined,
    PlaySquareOutlined, CustomerServiceOutlined, ReadOutlined, EnvironmentOutlined, CompassOutlined, FlagOutlined,
    CarOutlined, SmileOutlined, ApiOutlined , LikeOutlined } from '@ant-design/icons';

import NewsPage from './views/NewsPage';
import FakesPage from './views/FakesPage';
import TragediesPage from './views/TragediesPage';
import { userRoles } from './constants/userRoles';
import DeclassifiedDocPage from './views/DeclassifiedDocPage';
import MuseumPage from './views/MuseumPage';
import MediaPage from './views/MediaPage';
import ZoneRulePage from './views/ZoneRulePage';
import GuidePage from './views/GuidePage';
import TicketPage from './views/TicketPage';
import PeopleHistoryPage from './views/PeopleHistoryPage';
import ActivitiesPage from './views/ActivitiesPage';
import FilmsPage from './views/FilmsPage';
import BooksPage from './views/BooksPage';
import OtherArtPage from './views/OtherArtPage';
import LocationPage from './views/LocationPage';
import RouteLocationsPage from './views/RouteLocationsPage';
import RouteConstructorPage from './views/RouteConstructorPage';
import RouteExhibitionPage from './views/RouteExhibitionPage';
import PartnersPage from './views/PartnersPage';
import AboutAppPage from './views/AboutAppPage';
import DevelopersPage from './views/DevelopersPage';

const routes = [
    {
        path: '/home',
        exact: false,
        main: Home,
        key: 'Home',
        private: true,
        acceptRoles: [userRoles.superAdmin, userRoles.admin, userRoles.publisher],
        children: [
            {
                path: '/users',
                exact: false,
                main: Users,
                key: 'Users',
                private: true,
                text: 'Користувачі',
                icon: UserOutlined,
                acceptRoles: [userRoles.superAdmin, userRoles.admin],
            },
            {
                exact: false,
                key: 'Information',
                private: true,
                text: 'Інформація',
                icon: InfoCircleOutlined,
                acceptRoles: [userRoles.publisher, userRoles.admin],
                children: [
                    {
                        path: '/news',
                        exact: false,
                        main: NewsPage,
                        key: 'News',
                        private: true,
                        text: 'Новини',
                        icon: BookOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/fakes',
                        exact: false,
                        main: FakesPage,
                        key: 'Fakes',
                        private: true,
                        text: 'Фейки',
                        icon: CommentOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/objects',
                        exact: false,
                        main: LocationPage,
                        key: 'Locations',
                        private: true,
                        text: 'Об\'єкти',
                        icon: EnvironmentOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    }
                ]
            },
            {
                exact: false,
                key: 'Stories',
                private: true,
                text: 'Історії',
                icon: HourglassOutlined,
                acceptRoles: [userRoles.publisher, userRoles.admin],
                children: [
                    {
                        path: '/tragedies',
                        exact: false,
                        main: TragediesPage,
                        key: 'Tragedies',
                        private: true,
                        text: 'Історія трагедії',
                        icon: AlertOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/declassified_doc',
                        exact: false,
                        main: DeclassifiedDocPage,
                        key: 'DeclassifiedDoc',
                        private: true,
                        text: 'Розсекречені документи',
                        icon: FileProtectOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/museum_history',
                        exact: false,
                        main: MuseumPage,
                        key: 'MuseumHistory',
                        private: true,
                        text: 'Медіатека',
                        icon: HddOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    // TODO: remove if not need
                    // {
                    //     path: '/media_history',
                    //     exact: false,
                    //     main: MediaPage,
                    //     key: 'MediaHistory',
                    //     private: false,
                    //     text: 'Медіатека',
                    //     icon: HddOutlined,
                    //     acceptRoles: [userRoles.publisher, userRoles.admin],
                    // },
                    {
                        path: '/people_history',
                        exact: false,
                        main: PeopleHistoryPage,
                        key: 'PeopleHistory',
                        private: true,
                        text: 'Люди',
                        icon: TeamOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    }
                ]
            },
            {
                exact: false,
                key: 'Art',
                private: true,
                text: 'Мистецтво',
                icon: CameraOutlined,
                acceptRoles: [userRoles.publisher, userRoles.admin],
                children: [
                    {
                        path: '/activities',
                        exact: false,
                        main: ActivitiesPage,
                        key: 'Activities',
                        private: true,
                        text: 'Заходи',
                        icon: SoundOutlined ,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/films',
                        exact: false,
                        main: FilmsPage,
                        key: 'Films',
                        private: true,
                        text: 'Фільми',
                        icon: PlaySquareOutlined   ,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/books',
                        exact: false,
                        main: BooksPage,
                        key: 'Books',
                        private: true,
                        text: 'Книги',
                        icon: ReadOutlined ,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/other',
                        exact: false,
                        main: OtherArtPage,
                        key: 'OtherArt',
                        private: true,
                        text: 'Інше',
                        icon: CustomerServiceOutlined  ,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    }
                ]
            },
            {
                key: 'Dare',
                private: true,
                text: 'Наважитись',
                icon: FireOutlined ,
                acceptRoles: [userRoles.publisher, userRoles.admin],
                children: [
                    {
                        path: '/zoneRules',
                        exact: false,
                        main: ZoneRulePage,
                        key: 'ZoneRules',
                        private: true,
                        text: 'Правила зони',
                        icon: ExceptionOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/guide',
                        exact: false,
                        main: GuidePage,
                        key: 'Guide',
                        private: true,
                        text: 'Обрати гіда',
                        icon: UserSwitchOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/ticket',
                        exact: false,
                        main: TicketPage,
                        key: 'Ticket',
                        private: true,
                        text: 'Купити квиток',
                        icon: DollarCircleOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                ]
            },
            {
                key: 'Routes',
                private: true,
                text: 'Маршрути',
                icon: CompassOutlined ,
                acceptRoles: [userRoles.publisher, userRoles.admin],
                children: [
                    {
                        path: '/routeConstructor',
                        exact: false,
                        main: RouteConstructorPage,
                        key: 'RouteConstructor',
                        private: true,
                        text: 'Конструктор',
                        icon: CarOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/routeLocations',
                        exact: false,
                        main: RouteLocationsPage,
                        key: 'RouteLocations',
                        private: true,
                        text: 'Локації',
                        icon: FlagOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/routeExhibition',
                        exact: false,
                        main: RouteExhibitionPage,
                        key: 'RouteExhibition',
                        private: true,
                        text: 'Виставки',
                        icon: BankOutlined,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                ]
            },
            {
                key: 'AboutUs',
                private: true,
                text: 'Про нас',
                icon: TrophyOutlined ,
                acceptRoles: [userRoles.publisher, userRoles.admin],
                children: [
                    {
                        path: '/developers',
                        exact: false,
                        main: DevelopersPage,
                        key: 'Developers',
                        private: true,
                        text: 'Про розробників',
                        icon: SmileOutlined ,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/aboutApp',
                        exact: false,
                        main: AboutAppPage,
                        key: 'AboutApp',
                        private: true,
                        text: 'Про додаток',
                        icon: ApiOutlined  ,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    },
                    {
                        path: '/partners',
                        exact: false,
                        main: PartnersPage,
                        key: 'Partners',
                        private: true,
                        text: 'Партнери ',
                        icon: LikeOutlined ,
                        acceptRoles: [userRoles.publisher, userRoles.admin],
                    }
                ]
            }
        ],
    },
    {
        path: '/login',
        exact: true,
        main: Login,
        key: 'Login',
        private: false,
    },
];

export default routes;
