import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { configure } from 'axios-hooks';
import axios from './core/setAxiosOptions';
import ProviderAuthContext from './context/AuthContext';

(function () {
    'use strict';

    var original = {
        open: XMLHttpRequest.prototype.open,
        send: XMLHttpRequest.prototype.send,
    };

    //@ts-ignore
    XMLHttpRequest.prototype.open = function (
        method,
        url,
        async,
        user,
        password,
    ) {
        if (url.indexOf('froala') !== -1) {
            return null;
        }
        return original.open.call(this, method, url, async, user, password);
    };
})();

configure({ axios });

ReactDOM.render(
    <React.StrictMode>
        <ProviderAuthContext>
            <App />
        </ProviderAuthContext>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
