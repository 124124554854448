import React from 'react';
import { Route } from 'react-router-dom';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

export const mapRoutes = (routes: any[], currentUserRole: string | null, path: string = '', handleChildren = false): any[] => {
    return routes.reduce((result, route) => {
        if (!route.private || route.acceptRoles.includes(currentUserRole)){
            result.push(
                <Route
                    key={route.key}
                    path={`${path}${route.path}`}
                    exact={route.exact}
                >
                    <route.main />
                </Route>
            )
            if(!!route.children && handleChildren){
                result.push(...mapRoutes(route.children, currentUserRole, path, handleChildren))
            }
        }
        return result;
    }, [])
};


export const mapMenuItems = (routes: any[], currentUserRole: string | null, path: string = ''): any[] => {
    return routes.reduce((result, route) => {
        if (route.acceptRoles.includes(currentUserRole)){
            result.push(
                !route.children ? 
                    <Menu.Item key={route.key} icon={<route.icon />}>
                        {route.text}
                        <Link to={`${path}${route.path}`} />
                    </Menu.Item>
                :
                    <SubMenu key={route.key} icon={<route.icon/>} title={route.text}>
                        { mapMenuItems(route.children, currentUserRole, path) }
                    </SubMenu>
            )
        }
        return result;
    }, [])
};
