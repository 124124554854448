import { notification } from 'antd';

const openNotificationWithIcon = (type: any, message = '', description = '') => {
    // @ts-ignore
    notification[type]({
        message: message,
        description: description,
        duration: 3
    })
};

export default openNotificationWithIcon;