import React, { useState, useEffect  } from 'react';
import RichTextEditor from './RichTextEditor';
import service from '../core/setAxiosOptions';
import { imageService } from '../services/imageService';
import {Button, Row, Col, Form, Modal, Input, Breadcrumb} from 'antd';
import { REQUIRED_MSG } from '../constants/common';
import openNotificationWithIcon from '../core/notifications';

const baseURL = 'about';

interface IAboutPageProps {
    dataCode: string
}

const AboutPage = ({
    dataCode
} : IAboutPageProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        getAbout();
    }, []);

    const getAbout = () => {
        service.get(`${baseURL}/${dataCode}`).then(res => {
            form.setFieldsValue(res.data);
        });
    };

    const updateAbout = (data) => {
        return service.put(baseURL, data);
    };

    const onSubmit = () => {
        form.validateFields().then(async (values) => {
            const data = {
                _id: form.getFieldValue('_id'),
                titleEN: values.titleEN,
                titleUA: values.titleUA,
                shortTextUA: values.shortTextUA,
                shortTextEN: values.shortTextEN,
                textUA: values.textUA,
                textEN: values.textEN,
            };
            
            const result = await updateAbout(data);
            
            if(result.status === 200) {
                openNotificationWithIcon('success', 'Статтю "Про нас" успішно оновлено!');
            }
        });
    };

    const saveImage = async (data: FormData) => {
        return imageService.uploadImage(data);
    };

    const deleteImage = async (id: string) => {
        return imageService.deleteImage(id);
    };

    return (
        <>
            <Row className="toolBar" style={{justifyContent: 'space-between'}}>
                <Col>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <span className='welcome'>Інформація</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span className='welcome'>Про нас</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col>
                    <Button onClick={onSubmit}>Оновити</Button>
                </Col>
            </Row>
            <Row className="toolBar">
                <Form
                    form={form}
                    name="basic"
                    layout={'vertical'}
                >
                    <Row style={{ justifyContent: 'space-around' }}>
                        <Col span={11}>
                            <Form.Item
                                label="Назва статтi (UA)"
                                name="titleUA"
                                rules={[
                                    {
                                        required: true,
                                        message: REQUIRED_MSG,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Назва статтi (EN)"
                                name="titleEN"
                                rules={[
                                    {
                                        required: true,
                                        message: REQUIRED_MSG,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Короткий опис (UA)"
                                name="shortTextUA"
                                rules={[
                                    {
                                        required: true,
                                        message: REQUIRED_MSG,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Короткий опис (EN)"
                                name="shortTextEN"
                                rules={[
                                    {
                                        required: true,
                                        message: REQUIRED_MSG,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Текст (UA)"
                                name="textUA"
                                required={true}
                                rules={[
                                    {
                                        validator: (rule: any, value: any, callback: any) => {
                                            if (!value || value === '<p><br></p>') {
                                                callback(REQUIRED_MSG);
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ]}>
                                <RichTextEditor
                                    value={form.getFieldValue('textUA')}
                                    setValue={(value) => form.setFieldsValue({
                                        textUA: value
                                    })}
                                    deleteImage={deleteImage}
                                    saveImage={saveImage}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Текст (EN)"
                                name="textEN"
                                required={true}
                                rules={[
                                    {
                                        validator: (rule: any, value: any, callback: any) => {
                                            if (!value || value === '<p><br></p>') {
                                                callback(REQUIRED_MSG);
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ]}>
                                <RichTextEditor
                                    value={form.getFieldValue('textEN')}
                                    setValue={(value) => form.setFieldsValue({
                                        textEN: value
                                    })}
                                    deleteImage={deleteImage}
                                    saveImage={saveImage}
                                />
                            </Form.Item>
                        </Col>
                        </Row>
                </Form>
            </Row>
        </>
    )
};

export default AboutPage;