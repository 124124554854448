import React, {useEffect, useState} from 'react';
import {
    Form,
    Modal,
    Input,
    InputNumber,
    Button,
    Row,
    Col,
    Upload,
    TimePicker,
    Select,
    Collapse,
    Checkbox,
} from 'antd';
import RichTextEditor from './RichTextEditor';
import { REQUIRED_MSG, TIME_FORMAT } from '../constants/common';
import moment from 'moment';
import service from './../core/setAxiosOptions';
import { imageService } from '../services/imageService';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { AudioUploader } from './AudioUploader';
import { imageInfoToAntdModel, imageURLToAntdModel } from '../helpers/imageToAntdModel';
import { uploadCustomRequest, updateImagesList, updateAudio } from '../helpers/filesLoadHandling';
import { checkFileSize } from '../helpers/checkFileSize';

const { Panel } = Collapse;
const object3dURL = '/object3d';

const ModalRouteLocations: React.FC<any> = ({
    defaultItem,
    visible,
    handleCancel,
    handleOk,
    dataURL
}) => {
    const [form] = Form.useForm();

    const [bgImage, setBgImage] = useState(defaultItem?.bgImage ? [imageURLToAntdModel(defaultItem.bgImage)] : [] as any[]);

    const [image360, setImage360] = useState(defaultItem?.image360 ? [imageURLToAntdModel(defaultItem.image360)] : [] as any[]);

    const [arImage, setArImage] = useState(defaultItem?.arImageTarget?.image ? [imageURLToAntdModel(defaultItem?.arImageTarget?.image)] : [] as any[]);

    const [arVideo, setArVideo] = useState(defaultItem?.arImageTarget?.video ? [imageURLToAntdModel(defaultItem?.arImageTarget?.video)] : [] as any[]);

    const [objects3d, setObjects3d] = useState<any[]>([]);

    const exhActiveKey =
        defaultItem?.arImageTarget?.image
        || defaultItem?.arImageTarget?.video
        || defaultItem?.arImageTarget?.imageWidth
        || (defaultItem?.arImageTarget?.isHorizontal && defaultItem?.arImageTarget?.isHorizontal !== null);

    useEffect(() => {
        getObjects3d();
    }, []);

    const getObjects3d = () => {
        service.get(object3dURL).then(res => {
            setObjects3d(res.data);
        });
    };

    const onFinish = () => {
        form.validateFields().then(async (values) => {

            const res: any = {
                ...(defaultItem ? { _id: defaultItem._id } : {}),
                titleEN: values.titleEN,
                titleUA: values.titleUA,
                textUA: values.textUA,
                textEN: values.textEN,
                audioUA: values.audioUA?.src || null,
                audioEN: values.audioEN?.src|| null,
                bgImage: bgImage[0]?.url || null,
                time: moment(values.time).format(TIME_FORMAT),
                object3d: values.object3d || null,
                image360: image360[0]?.url || null,
                timeOnlineUA: values?.audioUA?.time ? moment.utc(moment.duration(Math.round(values.audioUA.time), 'seconds').as('milliseconds')).format(TIME_FORMAT) : null,
                timeOnlineEN: values?.audioEN?.time ? moment.utc(moment.duration(Math.round(values.audioEN.time), 'seconds').as('milliseconds')).format(TIME_FORMAT) : null,
                ...!arImage[0]?.url && !arVideo[0]?.url && !values.arImageTarget?.imageWidth && !values.arImageTarget?.isHorizontal
                    ? { arImageTarget: null }
                    : { 
                        arImageTarget: {
                            image: arImage[0]?.url || null,
                            imageWidth: values.arImageTarget?.imageWidth || null,
                            video: arVideo[0]?.url || null,
                            isHorizontal: values.arImageTarget && values.arImageTarget.isHorizontal !== null
                                ? values.arImageTarget?.isHorizontal
                                : false,
                        }
                    },
            };

            const result = await handleOk(res);
            if(result.status === 200) {
                form.resetFields();
            }
        });
    };

    const onCancel = () => {
        form.resetFields();
        handleCancel();
    };

    const handleUploadChange = (listName: string, setList: any) =>
        (info: UploadChangeParam<UploadFile<any>>) => {
            const list = eval(listName);
            if (info.file.status === 'removed') {
                const toUpdate = list.filter(image => image.uid !== info.file.uid);
                setList(toUpdate);
                imageService.deleteImage(info.file.uid).then(() => updateImagesList(defaultItem?._id, listName, toUpdate, dataURL, false));
            } else if (info.file.status) {
                const images = [...list];
                const imageToAdd = imageInfoToAntdModel(info);
                setList(
                    images.some(image => image.uid === info.file.uid) ? 
                        images.map(image => image.uid === info.file.uid ? imageToAdd : image) : [...images, imageToAdd]
                );
            }
        };

    return(
        <Modal
            centered
            maskClosable={false}
            title={defaultItem ? 'Редагувати' : 'Створити'}
            visible={visible}
            onCancel={onCancel}
            bodyStyle={{
                maxHeight: '75vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            footer={
                <Row justify={'end'}>
                    <Button onClick={onCancel}>Вiдмiна</Button>
                    <Button type={'primary'} onClick={onFinish}>
                        {defaultItem ? 'Зберегти' : 'Створити'}
                    </Button>
                </Row>
            }
            width={'80%'}
        >
            <Form
                form={form}
                name="basic"
                initialValues={defaultItem ? {
                    ...defaultItem,
                    time: moment(defaultItem?.time, TIME_FORMAT),
                    audioUA: { src: defaultItem.audioUA || null, time: moment.duration(defaultItem.timeOnlineUA).asSeconds() || null },
                    audioEN: { src: defaultItem.audioEN || null, time: moment.duration(defaultItem.timeOnlineEN).asSeconds() || null },
                    object3d: defaultItem?.object3d?._id
                } : {}}
                layout={'vertical'}
            >
                <Row style={{ justifyContent: 'space-around' }}>
                <Col span={11}>
                        <Form.Item
                            label="3D об'єкт"
                            name="object3d"
                        >
                            <Select allowClear>
                                {objects3d &&
                                objects3d.map(c =>
                                    <Select.Option key={c._id} value={c._id}>{c.titleUA}/{c.titleEN}</Select.Option>
                                )
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Час відвідування"
                            name="time"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <TimePicker 
                                format={TIME_FORMAT}
                                placeholder='Оберіть час'/>
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Назва локації (UA)"
                            name="titleUA"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Назва локації (EN)"
                            name="titleEN"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Текст (UA)"
                            name="textUA"
                            required={true}
                            rules={[
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value === '<p><br></p>') {
                                            callback(REQUIRED_MSG);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]}>
                            <RichTextEditor
                                value={form.getFieldValue('textUA')}
                                setValue={(value) => form.setFieldsValue({
                                    textUA: value
                                })}
                                deleteImage={imageService.deleteImage}
                                saveImage={imageService.uploadImage}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Текст (EN)"
                            name="textEN"
                            required={true}
                            rules={[
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value === '<p><br></p>') {
                                            callback(REQUIRED_MSG);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]}>
                            <RichTextEditor
                                value={form.getFieldValue('textEN')}
                                setValue={(value) => form.setFieldsValue({
                                    textEN: value
                                })}
                                deleteImage={imageService.deleteImage}
                                saveImage={imageService.uploadImage}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Аудіо файл (UA)"
                            name="audioUA"
                        >
                            <AudioUploader 
                                value={form.getFieldValue('audioUA')}
                                setValue={(audio) => {
                                    form.setFieldsValue({
                                        audioUA: audio
                                    });
                                    updateAudio(defaultItem?._id, 'audioUA', audio?.src, dataURL);
                                }}/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Аудіо файл (EN)"
                            name="audioEN"
                        >
                            <AudioUploader
                                value={form.getFieldValue('audioEN')}
                                setValue={(audio) => {
                                    form.setFieldsValue({
                                        audioEN: audio
                                    });
                                    updateAudio(defaultItem?._id, 'audioEN', audio?.src, dataURL);
                                }}/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Зображення локації"
                            name="bgImage"
                            rules={[
                                {
                                    required: true,
                                    message: REQUIRED_MSG,
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={checkFileSize}
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={bgImage}
                                onChange={handleUploadChange('bgImage', setBgImage)}
                            >
                                <Button disabled={!!bgImage?.length}>Завантажити</Button>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            label="Зображення 360"
                            name="image360"
                        >
                            <Upload
                                customRequest={uploadCustomRequest}
                                listType="picture"
                                fileList={image360}
                                onChange={handleUploadChange('image360',setImage360)}
                            >
                                <Button disabled={!!image360?.length}>Завантажити</Button>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Collapse ghost { ... exhActiveKey ? { activeKey: [1] } : {} }> 
                            <Panel header="Exhibition fields" key="1" forceRender>
                               <Row style={{ justifyContent: 'space-around' }}>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Зображення"
                                            name={['arImageTarget','image']}
                                        >
                                            <Upload
                                                customRequest={uploadCustomRequest}
                                                listType="picture"
                                                fileList={arImage}
                                                onChange={handleUploadChange('arImage',setArImage)}
                                            >
                                                <Button disabled={!!image360?.length}>Завантажити</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Ширина зображення"
                                            name={['arImageTarget','imageWidth']}>
                                            <InputNumber style={{width: '100px'}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Відео"
                                            name={['arImageTarget','video']}
                                        >
                                            <Upload
                                                customRequest={uploadCustomRequest}
                                                listType="picture"
                                                fileList={arVideo}
                                                onChange={handleUploadChange('arVideo',setArVideo)}
                                            >
                                                <Button disabled={!!image360?.length}>Завантажити</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} >
                                        <Form.Item
                                            label="Чи горизонтальне"
                                            initialValue={null}
                                            name={['arImageTarget','isHorizontal']}
                                            valuePropName="checked"
                                        >
                                            <Checkbox/>
                                        </Form.Item>
                                    </Col>
                               </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );

};

export default ModalRouteLocations;