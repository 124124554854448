import React from 'react';
import * as columns  from '../utils/GridColumns';
import CommonArticlePage from '../components/CommonArticlePage';
import ModalEditLocation from '../components/ModalEditLocation';

const dataURL = '/location';
const deepLink = 'object';

const LocationPage = () => {
    return(
        <CommonArticlePage
        breadcrumbItems={['Інформація', 'Об\'єкти']}
            dataURL={dataURL}
            deepLink={deepLink}
            customModal={ModalEditLocation}
            columns={[
                columns.title,
                columns.shortText,
                columns.object3d,
                columns.isNeedPublicize,
                columns.getOperations()
            ]}
        />
    );
};

export default LocationPage;
