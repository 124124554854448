import {message} from 'antd';

const maxImgSize = 25; //MB

export function checkFileSize(file) {
    if (file.size / 1024 / 1024 > maxImgSize) {
        message.error(`Image must smaller than ${maxImgSize}MB!`);
        return false;
    }
    return true;
}