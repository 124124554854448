import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { imageService } from '../services/imageService';
import service from './../core/setAxiosOptions';

export const uploadCustomRequest = (options: RcCustomRequestOptions) => {
    const fData = new FormData();
    fData.append('file', options.file);
    imageService.uploadImage(fData)
        .then((res) => {
            options.onSuccess(res, options.file);
        })
        .catch((e) => {
            options.onError(e);
        });
};


export const updateImagesList = async (id: string, field: string, toUpdate: any[], url: string, handleDuplicate: boolean) => {
    return id ? service.put(url, {
        _id: id, 
        [field]: toUpdate?.length ? toUpdate.map(image => image.url) : null,
        ...(handleDuplicate && field.includes('UA') ? { imagesEN: toUpdate.map(image => image.url) || null } : {})
    }) : Promise.resolve();
}

export const updateAudio = async (id: string, field: string, toUpdate: any, url: string) => {
    return id ? service.put(url, {
        _id: id, 
        [field]: toUpdate || null,
    }) : Promise.resolve();
}